import React, { Component } from "react";
import Select from "react-select";
import moment from "moment";
import "./CView.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { GlobalContext } from "../../../mycontext";
import ResetButton from "../ResetButton/ResetButton";
import Sideform from "../../SideComponents/sideform";
import NatalChart from "./Natal_chart";
// import { FiCalendar } from "react-icons/fi";
import SectionComponent from "./SectionComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';


const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const ordinalDate = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const toDate = (dateString) => {
  var dateParts = dateString.split("-");

  // month is 0-based, that's why we need dataParts[1] - 1
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};

const computeCurrentDashaDate = (data) => {
  let requiredData = data.vimsottari_dasha_days_only.mahadashalist;
  let today = new Date();
  let notToday = data.date_of_birth;

  if (
    today >= toDate(requiredData[0].start_date) &&
    today <= toDate(requiredData[requiredData.length - 1].start_date)
  ) {
    return moment(today).format("DD-MM-YYYY");
  } else {
    return notToday;
  }
};

const filterOptions = [
  { value: "Maha Dasha", label: "Vimsottari Dasha" },
  { value: "Yogini Dasha", label: "Yogini Dasha" },
];

const valueOptions = [
  { value: 0, label: "Moon Chart" },
  { value: 1, label: "D1" },
  { value: 2, label: "D2" },
  { value: 3, label: "D3" },
  { value: 4, label: "D4" },
  { value: 5, label: "D7" },
  { value: 6, label: "D9" },
  { value: 7, label: "D10" },
  { value: 8, label: "D12" },
  { value: 9, label: "D16" },
  { value: 10, label: "D20" },
  { value: 11, label: "D24" },
  { value: 12, label: "D27" },
  { value: 13, label: "D30" },
  { value: 14, label: "D40" },
  { value: 15, label: "D45" },
  { value: 16, label: "D60" },
  { value: 17, label: "Before Trikona Reduction" },
  { value: 18, label: "After Trikona Reduction" },
  { value: 19, label: "After EK Reduction" },
  { value: 20, label: "Bhinnashtak Varga - Sun" },
  { value: 21, label: "Bhinnashtak Varga - Moon" },
  { value: 22, label: "Bhinnashtak Varga - Mars" },
  { value: 23, label: "Bhinnashtak Varga - Merc" },
  { value: 24, label: "Bhinnashtak Varga - Jupt" },
  { value: 25, label: "Bhinnashtak Varga - Venu" },
  { value: 26, label: "Bhinnashtak Varga - Satn" },
  { value: 27, label: "Bhinnashtak Varga - Asc" },
  { value: 28, label: "Shadbala ranked bars" },
  { value: 29, label: "Bhavbala ranked bars" },
  { value: 30, label: "Five Fold Relationships" },
  { value: 31, label: "Planet Positions" },
  { value: 32, label: "Transit - Asc Based" },
  { value: 33, label: "Transit - Moon Rashi Based" },
  { value: 34, label: "Swansh Kundali" },
  { value: 35, label: "Karkansh Kundali" },
  { value: 36, label: "Non Luminaries" },
  { value: 37, label: "Special Ascendants" },
];

var Mapper = {
  "Maha Dasha": "vimsottari_dasha_days_only",
  "Yogini Dasha": "yogini_dasha",
};

const customStyles = {
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    backgroundColor: "#fbfcfd",
  }),
};
class CView extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      horoscopeData:
        JSON.parse(window.localStorage.getItem("horoscopeData")) || null,
      requestParamsHoroscope:
        JSON.parse(window.localStorage.getItem("requestParamsHoroscope")) ||
        null,
      dashaType: { value: "Maha Dasha", label: "Vimsottari Dasha" },
      currentMahaDasha: null,
      ArdhaDasha: null,
      PradhamaDasha: null,
      currentMaha: 0,
      currentPradhama: 0,
      dashaDate: null,
      selectedValue: [6, 31, 28, 17, 33],
      isOpen: false,
      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.dateChange = this.dateChange.bind(this);
    this.FilterData = this.FilterData.bind(this);
  }

  componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(window.localStorage.getItem("googleAuthObj"));
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
  
    let queryString = getQueryParam('cb');
    let horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    let requestParams = JSON.parse(window.localStorage.getItem("requestParamsHoroscope"));
  
    if (queryString) {
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
      requestParams = JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`));
  
      this.setState({ 
        horoscopeData: JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`)), 
        requestParamsHoroscope: JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`)) 
      });
  
      this.setState({
        dashaDate: computeCurrentDashaDate(horoscopeData),
      });
      this.FilterData(this.state.dashaType.value, horoscopeData);
    } else {
      if (this.state.requestParamsHoroscope !== null && Object.keys(this.context.requestParams).length === 0) {
        this.context.setRequestParamsHoroscope(requestParams);
      }
      if (this.state.horoscopeData === null) {
        this.context.handleHoroscopeForm(true, "open");
      } else {
        this.setState({
          dashaDate: computeCurrentDashaDate(horoscopeData),
        });
        this.context.setHoroscopeData(horoscopeData);
        this.FilterData(this.state.dashaType.value, horoscopeData);
      }
    }
  }
  

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.setState(
        {
          dashaDate: computeCurrentDashaDate(this.state.horoscopeData),
        },
        () =>
          this.FilterData(
            this.state.dashaType.value,
            this.state.horoscopeData
          )
      );
      this.setState({ horoscopeData: this.state.horoscopeData });
      this.setState({
        requestParamsHoroscope: this.state.requestParamsHoroscope,
      });
    }
  
    const storedHoroscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    if (
      storedHoroscopeData &&
      JSON.stringify(storedHoroscopeData.basic_details) !== JSON.stringify(this.state.horoscopeData.basic_details)
    ) {
      console.log("Data updated, refreshing state");
      this.setState({ horoscopeData: storedHoroscopeData });
    }
  }
  

  Datify = (date) => {
    date = date.split(" ")[0];
    if (date === "00-00-0000") {
      return "";
    }

    let arr = date.split("-");
    let day;
    if (arr[0] < 10) {
      day = arr[0].replace("0", "");
    } else {
      day = arr[0];
    }

    return localStorage.getItem("UserDefaultLanguage") === "English"
      ? day +
          ordinalDate(day) +
          " " +
          this.props.t(MonthNames[arr[1] - 1]) +
          ", " +
          arr[2]
      : day + " " + this.props.t(MonthNames[arr[1] - 1]) + ", " + arr[2];
  };

  FilterData(dashaType, horoscopeData) {
    var required = Mapper[dashaType];
    var mahadashaData = horoscopeData[required].mahadashalist;
    for (let iter in mahadashaData) {
      let current = mahadashaData[iter];
      if (
        moment(this.state.dashaDate, "DD-MM-YYYY") >=
          moment(current.start_date.split(" ")[0], "DD-MM-YYYY") &&
        moment(this.state.dashaDate, "DD-MM-YYYY") <
          moment(current.end_date.split(" ")[0], "DD-MM-YYYY")
      ) {
        this.setState(
          (state) => {
            return {
              currentMahaDasha: current,
              currentMaha: iter,
              ArdhaDasha: current.sub_list,
            };
          },
          () => this.FilterPradhamaDasha(null)
        );
      } else {
        continue;
      }
    }
  }

  FilterPradhamaDasha(index) {
    if (index === null) {
      for (let iter in this.state.ArdhaDasha) {
        let current = this.state.ArdhaDasha[iter];
        if (
          moment(this.state.dashaDate, "DD-MM-YYYY") >=
            moment(current.start_date.split(" "), "DD-MM-YYYY") &&
          moment(this.state.dashaDate, "DD-MM-YYYY") <
            moment(current.end_date.split(" "), "DD-MM-YYYY")
        ) {
          this.setState((state) => {
            return {
              currentArdha: iter,
              PradhamaStartDate: this.Datify(current.start_date),
              PradhamaEndDate: this.Datify(current.end_date),
              PradhamaDasha: current.sub_list,
            };
          });
        }
      }
    } else {
      this.setState({
        PradhamaDasha: this.state.ArdhaDasha[index].sub_list,
        currentArdha: index,
        PradhamaStartDate: this.Datify(this.state.ArdhaDasha[index].start_date),
        PradhamaEndDate: this.Datify(this.state.ArdhaDasha[index].end_date),
      });
    }
  }
  primaryArdhaText(text, sdate, edate) {
    if (
      moment(this.state.dashaDate, "DD-MM-YYYY") >=
        moment(sdate, "DD-MM-YYYY") &&
      moment(this.state.dashaDate, "DD-MM-YYYY") < moment(edate, "DD-MM-YYYY")
    ) {
      return (
        <div
          className="d-flex"
          style={{
            width: "110%",
            position: "absolute",
            top: "-27px",
            left: "37px",
          }}
        >
          <span>{text + " "}&nbsp;&nbsp;</span>
          <span>{this.Datify(sdate)} &nbsp; </span>
          <span>- &nbsp;</span>
          <span>{this.Datify(edate)}</span>
        </div>
      );
    }
  }
  checkDate(lord, current, start, end, index) {
    if (
      moment(current, "DD-MM-YYYY") >= moment(start, "DD-MM-YYYY") &&
      moment(current, "DD-MM-YYYY") < moment(end, "DD-MM-YYYY")
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleChangeDiv = (event, i) => {
    let val = event.target.value;
    this.setState((state) => {
      const selectedValue = state.selectedValue.map((item, j) => {
        if (j === i) {
          return parseInt(val);
        } else {
          return item;
        }
      });

      return {
        selectedValue,
      };
    });
  };

  handleChangeAstak = (event) => {
    this.setState({ astakValue: event.target.value }, () =>
      this.filterAstakData(this.state.horoscopeData)
    );
  };

  handleChangeBar = (event) => {
    this.setState({ barValue: event.target.value });
  };

  handleDashaTypeChange = (e) => {
    this.setState({ dashaType: e }, () =>
      this.FilterData(this.state.dashaType.value, this.state.horoscopeData)
    );
  };

  dateChange(date) {
    date = moment(date).format("DD-MM-YYYY");
    this.setState(
      {
        dashaDate: date,
      },
      () =>
        this.FilterData(this.state.dashaType.value, this.state.horoscopeData)
    );

    this.setState({ isOpen: !this.state.isOpen });
  }
  handleClick = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  convertToLanguageMultiple = (obj) => {
    obj.map((i, value) => {
      i["label"] = this.props.t(i["label"]);
      return i;
    });
    return obj;
  };
  convertToLanguageSingle = (obj) => {
    obj["label"] = this.props.t(obj["label"]);
    return obj;
  };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if (
      this.state.horoscopeData !== null &&
      this.state.requestParamsHoroscope !== null &&
      Object.keys(this.state.horoscopeData).length > 0 &&
      typeof this.state.horoscopeData !== "undefined"
    ) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"cview"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div className="cview-wrapper">
            <div className="card">
              <div className="card-body" style={{ padding: "0px 0" }}>
                {/*START Card header */}
                <div className="section-title">
                  <div className="row">
                    <div className="col-md-7 col-6 d-flex">
                      <div className="drp-currentDasha pt-1">
                        <Select
                          value={this.convertToLanguageSingle(
                            this.state.dashaType
                          )}
                          onChange={this.handleDashaTypeChange}
                          options={this.convertToLanguageMultiple(
                            filterOptions
                          )}
                          width={400}
                          styles={customStyles}
                        />
                        <div className="mt-0">
                          <span className="dasha-name" id="asOndate">
                            {t("As on")}: {this.Datify(this.state.dashaDate)}
                          </span>{" "}
                          <button
                            className="example-custom-input"
                            onClick={this.handleClick}
                          >
                            <img
                              src={require("../../../assets/img/icons/calendar.png")}
                              alt="calendar"
                              style={{ width: "24px" }}
                            />
                          </button>
                          {this.state.isOpen && (
                            <DatePicker
                              onChange={this.dateChange}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              inline
                              onClickOutside={this.handleClick}
                              selected={toDate(this.state.dashaDate)}
                              minDate={toDate(
                                this.state.horoscopeData.date_of_birth
                              )}
                              maxDate={toDate(
                                this.state.horoscopeData
                                  .vimsottari_dasha_days_only.mahadashalist[
                                  this.state.horoscopeData
                                    .vimsottari_dasha_days_only.mahadashalist
                                    .length - 1
                                ].start_date
                              )}
                            />
                          )}
                        </div>
                      </div>
                      {this.state.currentMahaDasha !== null &&
                      this.state.ArdhaDasha !== null &&
                      this.state.PradhamaDasha !== null ? (
                        <div className="d-flex ml-2">
                          <>
                            <div className="dasha-details pr-2">
                              <p>
                                <b>{t("MD")} - </b>{" "}
                                <span className="dashaPlanet">
                                  {" "}
                                  {t(this.state.currentMahaDasha.lord)}
                                </span>{" "}
                                &nbsp;
                                <span>
                                  {" "}
                                  {this.Datify(
                                    this.state.currentMahaDasha.start_date
                                  )}
                                </span>{" "}
                                <span> - </span>
                                <span>
                                  {" "}
                                  {this.Datify(
                                    this.state.currentMahaDasha.end_date
                                  )}
                                </span>
                              </p>
                              <p>
                                <b>{t("AD")} - </b>
                                {this.state.ArdhaDasha.map((ardha, index) =>
                                  this.checkDate(
                                    ardha.lord,
                                    this.state.dashaDate,
                                    ardha.start_date,
                                    ardha.end_date
                                  ) ? (
                                    <>
                                      <span className="dashaPlanet">
                                        {" "}
                                        {t(ardha.lord)}
                                      </span>{" "}
                                      &nbsp;
                                      <span>
                                        {" "}
                                        {this.Datify(ardha.start_date)}
                                      </span>{" "}
                                      <span> - </span>
                                      <span>
                                        {" "}
                                        {this.Datify(ardha.end_date)}
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </p>
                              <p
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  top: "45px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <b>{t("PD")} - </b>
                                {this.state.PradhamaDasha.map(
                                  (pradhama, index) =>
                                    this.checkDate(
                                      pradhama.lord,
                                      this.state.dashaDate,
                                      pradhama.start_date,
                                      pradhama.end_date
                                    ) ? (
                                      <>
                                        <span className="dashaPlanet">
                                          {" "}
                                          {t(pradhama.lord)}
                                        </span>{" "}
                                        &nbsp;
                                        <span>
                                          {" "}
                                          {this.Datify(pradhama.start_date)}
                                        </span>{" "}
                                        <span> - </span>
                                        <span>
                                          {" "}
                                          {this.Datify(pradhama.end_date)}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="col-md-4 col-6 relative">
                      <ResetButton parent="cview" />
                    </div>
                  </div>
                </div>
                {/*END Card header */}
                {/* <div className="divider"></div> */}

                <div
                  className="charts-container"
                  style={{ overflowY: "scroll" }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-6">
                    <div className="row">
                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div
                          className="chartbox"
                          style={{ marginBottom: "-40px" }}
                        >
                          <div className="d-flex">
                            <h4 className="chart-title">{t("Natal Chart")}</h4>
                          </div>
                          <NatalChart
                            data={
                              this.state.horoscopeData.divisional_charts[0]
                                .list_of_houses
                            }
                            className="svg1"
                            id="container1"
                            hoverData={
                              this.state.horoscopeData.natal_chart.all_planets
                            }
                            name="natalchart"
                            Asc={t("Asc")}
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div
                          className="chartbox"
                          style={{ marginBottom: "-40px" }}
                        >
                          <div className="d-flex">
                            <select
                              className="drp custom-select"
                              value={t(this.state.selectedValue[0])}
                              onChange={(e, i) => this.handleChangeDiv(e, 0)}
                              style={{ width: "300px" }}
                            >
                              {valueOptions.map((option) => (
                                <option
                                  key={option + option.value}
                                  value={option.value}
                                >
                                  {t(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <SectionComponent
                            section="2"
                            horoscopeData={this.state.horoscopeData}
                            value={parseInt(this.state.selectedValue[0])}
                            requestParamsHoro={
                              this.state.requestParamsHoroscope
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div
                          className="chartbox"
                          style={{ marginBottom: "-40px" }}
                        >
                          <div className="d-flex" style={{ width: "100%" }}>
                            <select
                              className="drp custom-select"
                              value={t(this.state.selectedValue[1])}
                              onChange={(e, i) => this.handleChangeDiv(e, 1)}
                              style={{ width: "300px" }}
                            >
                              {valueOptions.map((option) => (
                                <option
                                  key={option.value + option.label}
                                  value={option.value}
                                >
                                  {t(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <SectionComponent
                            section="3"
                            horoscopeData={this.state.horoscopeData}
                            value={parseInt(this.state.selectedValue[1])}
                            requestParamsHoro={
                              this.state.requestParamsHoroscope
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div className="chartbox">
                          <div className="d-flex">
                            <select
                              className="drp custom-select"
                              value={t(this.state.selectedValue[2])}
                              onChange={(e, i) => this.handleChangeDiv(e, 2)}
                              style={{ width: "300px" }}
                            >
                              {valueOptions.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {t(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <SectionComponent
                            section="4"
                            horoscopeData={this.state.horoscopeData}
                            value={parseInt(this.state.selectedValue[2])}
                            requestParamsHoro={
                              this.state.requestParamsHoroscope
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div className="chartbox">
                          <div className="d-flex">
                            <select
                              className="drp custom-select"
                              value={t(this.state.selectedValue[3])}
                              onChange={(e, i) => this.handleChangeDiv(e, 3)}
                              style={{ width: "300px" }}
                            >
                              {valueOptions.map((option) => (
                                <option value={option.value}>
                                  {t(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <SectionComponent
                            section="5"
                            horoscopeData={this.state.horoscopeData}
                            value={parseInt(this.state.selectedValue[3])}
                            requestParamsHoro={
                              this.state.requestParamsHoroscope
                            }
                          />
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        style={{ margin: "0", padding: "0" }}
                      >
                        <div className="chartbox">
                          <div className="d-flex">
                            <select
                              className="drp custom-select"
                              value={t(this.state.selectedValue[4])}
                              onChange={(e, i) => this.handleChangeDiv(e, 4)}
                              style={{ width: "300px" }}
                            >
                              {valueOptions.map((option) => (
                                <option value={option.value}>
                                  {t(option.label)}
                                </option>
                              ))}
                            </select>
                          </div>

                          <SectionComponent
                            section="6"
                            horoscopeData={this.state.horoscopeData}
                            value={parseInt(this.state.selectedValue[4])}
                            requestParamsHoro={
                              this.state.requestParamsHoroscope
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="nav-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Shadbala Table</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Chalit View</Breadcrumb.Item>
              <Breadcrumb.Item href="#">Dasha Navigation</Breadcrumb.Item>
            </Breadcrumb>
          </div> */}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"cview"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
        </>
      );
    }
  }
}

export default withTranslation()(CView);
