import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";
import { GlobalContext } from "../../../mycontext";
import Api from "../../../API/Api";
//for auto translate
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";

var _ = require("lodash");

class Autocomplete extends Component {
  static contextType = GlobalContext;
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.city,
      cityMap: {},
      flag: false,
      text: "",
    };
    this.Api = new Api();
    this.fetchSuggestions = _.debounce(this.fetchSuggestions, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetInputText) {
      this.setState({ userInput: "" });
      this.props.handleChange("reset");
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.country !== this.props.country) {
      this.setState({ userInput: "" });
    }
  };

  fetchSuggestions(userInput) {
    // console.log("in fetch");
    if (this.props.mode === "Horoscope") {
      this.Api.fetchSuggestionsCityAndState(
        userInput,
        this.context.horoscopeCountry
      ).then((response) => {
        if (response) {
          if (response.data) {
            // console.log(response.data.Patterns_matched.slice(0, 6));
            let reqData = response.data.Patterns_matched;

            if (reqData.length === 0) {
              this.props.horoscopePlaceErrorChange("Please Enter Correct City");
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
              });
            } else {
              this.props.horoscopePlaceErrorChange(null);
              let cityMap = {};
              for (let i in reqData) {
                cityMap[reqData[i][0]] = reqData[i][1];
              }
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
                cityMap: cityMap,
              });
            }
          }
        } else {
          // console.log("Could not fetch data");
        }
      });
    } else if (this.props.mode === "Panchang") {
      this.Api.fetchSuggestionsCityAndState(
        userInput,
        this.context.panchangCountry
      ).then((response) => {
        if (response) {
          if (response.data) {
            // console.log(response.data.Patterns_matched.slice(0, 6));
            let reqData = response.data.Patterns_matched;

            if (reqData.length === 0) {
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
              });
            } else {
              let cityMap = {};
              for (let i in reqData) {
                cityMap[reqData[i][0]] = reqData[i][1];
              }
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
                cityMap: cityMap,
              });
            }
          }
        } else {
          // console.log("Could not fetch data");
        }
      });
    } else if (this.props.mode === "Transit") {
      this.Api.fetchSuggestionsCityAndState(
        userInput,
        this.context.transitCountry
      ).then((response) => {
        if (response) {
          if (response.data) {
            // console.log(response.data.Patterns_matched.slice(0, 6));
            let reqData = response.data.Patterns_matched;

            if (reqData.length === 0) {
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
              });
            } else {
              let cityMap = {};
              for (let i in reqData) {
                cityMap[reqData[i][0]] = reqData[i][1];
              }
              this.setState({
                filteredSuggestions: response.data.Patterns_matched,
                cityMap: cityMap,
              });
            }
          }
        } else {
          // console.log("Could not fetch data");
        }
      });
    }
  }

  // Event fired when the input value is changed
  onChange = (e) => {
    this.props.handleChange("userInput");
    const userInput = e.currentTarget.value;
    if (userInput.match(/^ *$/) === null) this.fetchSuggestions(userInput);

    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = async (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    }); 

  
    let cityId = this.state.cityMap[e.currentTarget.innerText];

    if (this.props.mode === "Panchang") {
      let result = await this.Api.fetchLocationDetails(cityId);
      this.context.setRequestParamsPanchang(result.data);
    } else if (this.props.mode === "Transit") {
      let result = await this.Api.fetchLocationDetails(cityId);
      this.context.setRequestParams(result.data);
    } else if (this.props.mode === "Horoscope") {
        
      let result = await this.Api.fetchLocationDetails(cityId);
      
      this.context.setRequestParamsHoroscope(result.data);
    } else {
      // console.log("Wrong Param");
    }
    //   this.props.handlePlaceDetailsChange(e.currentTarget.innerText);
    // }
  };

  // Event fired when the user presses a key down
  onKeyDown = async (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      e.preventDefault();
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion][0],
      });
      let cityId = filteredSuggestions[activeSuggestion][1];

      if (this.props.mode === "Panchang") {
        let result = await this.Api.fetchLocationDetails(cityId);
        this.context.setRequestParamsPanchang(result.data);
      } else if (this.props.mode === "Transit") {
        let result = await this.Api.fetchLocationDetails(cityId);
        this.context.setRequestParams(result.data);
      } else if (this.props.mode === "Horoscope") {
        let result = await this.Api.fetchLocationDetails(cityId);
        this.context.setRequestParamsHoroscope(result.data);
      } else {
        // console.log("Wrong Param");
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions" style={{ width: "70%" }}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion[0]}
                </li>
              );
            })}
          </ul>
        );
      } else if (filteredSuggestions.length === 0) {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        {/* <ReactTransliterate
          placeholder="english text translation"
          value={this.state.text}
          className="form-control"
          style={{ width: "240px", "margin-bottom": "15px" }}
          onChangeText={(text) => {
            this.setState({ text: text });
          }}
          lang="hi"
        /> */}
        <input
          // autoComplete="new-password"
          autoComplete="off"
          id="auto_complete1"
          ref="cpDev1"
          type="search"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={this.state.userInput}
          className="form-control"
          style={{ width: "70%" }}
          disabled={
            (this.props.mode === "Horoscope" &&
              this.context.horoscopeCountry === null) ||
            (this.props.mode === "Panchang" &&
              this.context.panchangCountry === null) ||
            (this.props.mode === "Transit" &&
              this.context.transitCountry === null)
          }
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
