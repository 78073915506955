import React, { Component } from "react";
import { GlobalContext } from "../../../mycontext";
import Table from "react-bootstrap/Table";
import BhavTable from "./BhavTable";
import BhavBalaBarGraph from "./BhavBalaBarGraph";
import Sideform from "../../SideComponents/sideform";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CollapseTable from "../ShadBala/CollapseTable";
import ShadBalaBarGraph from "../ShadBala/ShadBalaBarGraph";
import ResetButton from "../ResetButton/ResetButton";
import VimshopakTable from "./VimshopakTable";
import VimshopakGraph from "./VimshopakGraph";
import { withTranslation } from "react-i18next";
import ChalitVimshopakBala from "./ChalitVimshopakBala";
import {getQueryParam} from '../../utils/utility';

function pad(n) {
  return n < 10 ? "0" + n : n;
}

function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

const date = new Date();
let time =
  pad(date.getHours()) +
  ":" +
  pad(date.getMinutes()) +
  ":" +
  pad(date.getSeconds());

const balaMapper = { 1: "Bhav Bala", 2: "Shad Bala" };
class BhavBala extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      tabledata: [],
      calendarDate: date,
      newTime: time,
      renderChoice: "table",
      tabNum: "1",
      vimshopakData: [],
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.CreateTableFormattedJson = this.CreateTableFormattedJson.bind(this);
  }
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
    let horoscopeData;
    let queryString = getQueryParam('cb');
    if(queryString){
      // alert(queryString);
       this.setState({cb_view:true});
       this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
 
       this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
     }
     if(queryString){
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`)
      );
     }
     else{
      horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
     }

   
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (
      requestParams !== null &&
      Object.keys(this.context.requestParams).length === 0
    ) {
      this.context.setRequestParamsHoroscope(requestParams);
    }
    if (
      horoscopeData !== null &&
      Object.keys(this.context.horoscopeData).length === 0
    ) {
      this.context.setHoroscopeData(horoscopeData);
    }
    if (horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.CreateTableFormattedJson(
        horoscopeData,
        balaMapper[this.state.tabNum]
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.CreateTableFormattedJson(
        this.props.horoscopeData,
        balaMapper[this.state.tabNum]
      );
    }
  }

  CreateTableFormattedJson(horoscopeData, bala) {
    var outerjson = [];
    if (bala === "Bhav Bala") {
      let data = horoscopeData.bhava_bala;
      let primaryKeys = Object.keys(data);
      for (let i in primaryKeys) {
        let json = {};
        let iterable = data[primaryKeys[i]];
        if (iterable instanceof Array) {
          json["Column1"] = humanize(primaryKeys[i]);
          for (let j in iterable) {
            let details = iterable[j];
            if (primaryKeys[i] !== "relative_rank") {
              json[String(details.bhava_number)] =
                details.unit_value.toFixed(2);
            } else {
              json[String(details.bhava_number)] = details.unit_value;
            }
          }
          json["children"] = [];
          outerjson.push(json);
        }
      }
      this.setState({ tabledata: outerjson });
    } else if (bala === "Shad Bala") {
      let data = horoscopeData.graha_bala;
      let primaryKeys = Object.keys(data);
      for (let i in primaryKeys) {
        let json = {};
        let iterable = data[primaryKeys[i]];
        if (iterable instanceof Array) {
          json["Column1"] = humanize(primaryKeys[i]);
          json["Column2"] = "";
          for (let j in iterable) {
            let details = iterable[j];
            if (primaryKeys[i] !== "relative_ranks") {
              json[details.planet_name] = details.unit_value.toFixed(2);
            } else {
              json[details.planet_name] = details.unit_value;
            }
          }
          outerjson.push(json);
        } else {
          json = {
            Column1: humanize(primaryKeys[i]),
            Column2: "",
            Sun: "",
            Moon: "",
            Mars: "",
            Mercury: "",
            Jupiter: "",
            Venus: "",
            Saturn: "",
          };
          let primaryKeysInner = Object.keys(iterable);
          outerjson.push(json);
          for (let k in primaryKeysInner) {
            let childjson = {};
            if (iterable[primaryKeysInner[i]] instanceof Array) {
              childjson["Column1"] = "";
              childjson["Column2"] = humanize(primaryKeysInner[k]);
              for (let l in iterable[primaryKeysInner[k]]) {
                let details = iterable[primaryKeysInner[k]][l];
                if (primaryKeys[i] !== "relative_ranks") {
                  childjson[details.planet_name] =
                    details.unit_value.toFixed(2);
                } else {
                  json[details.planet_name] = details.unit_value;
                }
              }
              outerjson.push(childjson);
            }
          }
        }
      }
      this.setState({ tabledata: outerjson });
    }
  }

  renderElement = (value) => {
    this.setState({ renderChoice: value });
  };

  formatVimshopakData = (data) => {
    let reqData = data.vimshopak_bala;
    let mainjson = [];
    for (let i in reqData) {
      let json = {};
      json = { ...reqData[i] };
      json["Bala"] = i;
      mainjson.push(json);
    }
    this.setState({ vimshopakData: mainjson });
  };

  handleTabChange = (value) => {
    if (value === "1") {
      this.setState({ tabNum: value });
      this.CreateTableFormattedJson(
        this.state.horoscopeData,
        balaMapper[value]
      );
    } else if (value === "2") {
      this.setState({ tabNum: value });
      this.CreateTableFormattedJson(
        this.state.horoscopeData,
        balaMapper[value]
      );
    } else if (value === "3") {
      this.setState({ tabNum: value });
      this.formatVimshopakData(this.state.horoscopeData);
    } else if (value === "4") {
      this.setState({ tabNum: value });
      // console.log();
    }
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Sideform
          show={this.context.horoscopeForm}
          mode={"Horoscope"}
          view={"bhavBala"}
          handleClose={this.context.handleHoroscopeForm}
          reset={false}
          history={this.props.history}
        />
        <div className="main-div px-1">
          <ResetButton />

          <div className="panel with-nav-tabs panel-warning">
            <div className="panel-heading">
              <Tabs
                style={{ padding: "5px 0 1px" }}
                id="controlled-tab-example"
                activeKey={this.state.value}
                onSelect={(k) => this.handleTabChange(k)}
              >
                <Tab eventKey={1} title={t("Bhav Bala")}></Tab>
                <Tab eventKey={2} title={t("Shad Bala")}></Tab>
                <Tab eventKey={3} title={t("Vimshopak Bala")}></Tab>
                <Tab eventKey={4} title={t("Chalit Vimshopak Bala")}></Tab>
              </Tabs>
            </div>
            <div>
              <center className="section-header">
                {this.state.tabNum === "1" || this.state.tabNum === "2" ? (
                  <>
                    <span className="main-title mr-2">
                      {this.state.tabNum === "1"
                        ? t("Bhav Bala")
                        : t("Shad Bala")}
                    </span>
                    <label
                      style={{
                        fontSize: "20px",
                        fontWeight: "normal",
                        fontFamily: "Lucida Bright",
                      }}
                    >
                      <input
                        type="radio"
                        name="optradio"
                        value="table"
                        checked={this.state.renderChoice === "table"}
                        onClick={() => this.renderElement("table")}
                        readOnly
                      />{" "}
                      {t("Table")}
                    </label>
                    <label
                      style={{
                        fontSize: "20px",
                        fontWeight: "normal",
                        fontFamily: "Lucida Bright",
                      }}
                    >
                      <input
                        type="radio"
                        name="optradio"
                        value="chart"
                        style={{ marginLeft: "20px" }}
                        onClick={() => this.renderElement("chart")}
                        checked={this.state.renderChoice === "chart"}
                        readOnly
                      />{" "}
                      {t("Chart")}
                    </label>
                  </>
                ) : (
                  <span className="main-title mr-2">{t("Vimshopak Bala")}</span>
                )}
                {this.state.tabNum === "1" ? (
                  this.state.renderChoice === "table" ? (
                    <BhavTable data={this.state.tabledata} />
                  ) : (
                    <BhavBalaBarGraph
                      horoscopeData={this.state.horoscopeData}
                    />
                  )
                ) : this.state.tabNum === "2" ? (
                  this.state.renderChoice === "table" ? (
                    <CollapseTable data={this.state.tabledata} />
                  ) : (
                    <ShadBalaBarGraph
                      horoscopeData={this.state.horoscopeData}
                    />
                  )
                ) : this.state.tabNum === "3" &&
                  this.state.vimshopakData.length > 0 ? (
                  <>
                    <VimshopakTable data={this.state.vimshopakData} />
                    <VimshopakGraph />
                  </>
                ) : this.state.tabNum === "4" ? (
                  <>
                    <ChalitVimshopakBala
                      horoscopeData={this.state.horoscopeData}
                    />
                  </>
                ) : (
                  <></>
                )}
              </center>
            </div>
            <br></br>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(BhavBala);
