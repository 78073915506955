import React, { useEffect, useState, useRef } from "react";
// import Pagination from "../CelebrityManagement/Pagination";
import { useHistory, withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "../basicsearch.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { basicSearch, queryBuilderSearch, searchByTemplateName } from "../../../API/CelebrityManagement_API";
import { Filters } from "../QueryBuilder/filters"
import Button from 'react-bootstrap/Button'
import { use } from "i18next";
import { parseInt } from "lodash";
import Api from "../../../API/Api"
import "./querybuilder.css";


//TODO: Use storage 
const DisplayResults = (props) => {
    const filterStorageKeyName = "QueryBuilderFilters";
    const aws_image_base = "https://omparashar-dev.s3.ap-south-1.amazonaws.com/images/";
    const [matches, setMatches] = useState([]);
    const [unfilteredMatches, setUnfilteredMatches] = useState(undefined)
    const [noOfMatches, setNoOfMatches] = useState(-1)
    const [outerPageNum, setouterPageNum] = useState(1)
    // const [totalOuterPages, setTotalOuterPages] = useState(props.number_of_pages||0)
    const totalOuterPages = useRef(0)
    const [internalMatchArray, setInternalMatchArray] = useState([]);
    const [tagOptions,setTagOptions]=useState({});
    const [filters, setFilters] = useState({ gender: undefined, country: undefined, from_year: undefined, to_year: undefined, tags: undefined })

    const [currentPageNum, setCurrentPageNum] = useState(1);
    // to store previous selected value of a page number
    const [previousPageNum, setPreviousPageNum] = useState(1);

    const [loading, setLoading] = useState(false);
    let history = useHistory();
    let recordsPerPage = 20;
    const [disablePrevBotton, setDisablePrevBotton] = useState(true);
    const [disableNextBotton, setDisableNextBotton] = useState(false);

    const [showFilters, setShowFilters] = useState(false);

    const [filterChanged, setFilterChanged] = useState(false);
    const [data, setData] = useState();
    const [category,setCategory]=useState(0);
    const [isLoading, setIsLoading] = useState(internalMatchArray.map(() => false));


    //internal {page num:{outer_page_num:[st,ed],outer_page2:[st,ed]},..}
    const [outerPageNumToInternalPageNumMap, setOuterPageNumToInternalPageNumMap] = useState({})


    useEffect(() => {
        // if(Object.keys(props.query).length === 0){
        //     alert("no query",props.query);
        //     return;
        // }
    }
        , [])

const resetFilters =()=>{
    var f = { gender: undefined, country: undefined, from_year: undefined, to_year: undefined, tags: undefined ,tag_options:[]};
    setFilters(f);
}
const genderMapper = {
    Male: 0,
    M: 0,
    पुरुष: 0,
    Female: 1,
    F: 1,
    स्त्री: 1,
    "Male to Female": 2,
    MF: 2,
    "पुरुष से महिला": 2,
    "Female to Male": 3,
    FM: 3,
    "महिला से पुरुष": 3,
    Others: 4,
    अन्य: 4,
    "Not Applicable": 5,
    "N/A": 5,
    "उपयुक्त नहीं": 5
};

    useEffect(() => {
        if (Object.keys(props.query).length === 0 && props.searchByTemplateNameFlag === false) {
            // alert("no query",props.query);
            return;
        }
        setLoading(true);
        if (currentPageNum > 1) {
            setDisablePrevBotton(false);
        }
        // alert("query"+JSON.stringify(props.query))
        makeAPICall(1, true).then(matches => {
            applyFilters(matches,{}).then(
            filtered_matches => setPageMap(filtered_matches, {}, 1, 1).then(
                page_map_array => {
                    // console.log('calling set internal array from initial load ', page_map_array);
                    setInternalArray(filtered_matches, 1, 'forward', page_map_array,1)
                    resetFilters();
                }
            ))})

    }
        , [props.query, props.templateName,])

    useEffect(() => {
        setLoading(true);
        // localStorage.setItem(filterStorageKeyName, JSON.stringify(filters));
        if (unfilteredMatches === undefined) {
            //initial load
            return;
        }
        if (outerPageNum !== 1 && outerPageNum !== undefined) {
            setFilterChanged(true);
            setCurrentPageNum(1);
            setPreviousPageNum(1);
            // alert("filter"+outerPageNum)
            makeAPICall(1, true).then(_matches => applyFilters(_matches).then(
                filtered_matches => {
                    var m = {}; setPageMap(filtered_matches, m, 1, 1).then(
                        page_map_array => {
                            // console.log('calling set internal array from initial load ', page_map_array);
                            setInternalArray(filtered_matches, 1, 'forward', page_map_array, 1)
                        }
                    )
                }))
        }
        else {
            if (currentPageNum !== 1) {
                setFilterChanged(true);
                setPreviousPageNum(1);
                setCurrentPageNum(1);
            }
            applyFilters(unfilteredMatches).then(
                filtered_matches => {
                    var m = {}; setPageMap(filtered_matches, m, 1, 1).then(
                        page_map_array => {
                            // console.log('calling set internal array from initial load ', page_map_array);
                            setInternalArray(filtered_matches, 1, 'backward', page_map_array,1)
                        }
                    )
                })
        }

    }, [filters])

    useEffect(() => {
        if (filterChanged) {
            setFilterChanged(false);
            return;
        }
        if (currentPageNum === 1) {
            setDisablePrevBotton(true);
        }
        if (previousPageNum > currentPageNum) {
            // backwards page navigation
            // console.log('back navigation')

            setDisableNextBotton(false);
            setInternalArray(matches, currentPageNum, 'backward', outerPageNumToInternalPageNumMap)
        }
        if (previousPageNum < currentPageNum && currentPageNum !== 1) {
            //currentPageNum!==1 is to avoid rendering at initial load

            // forward navigation
            setDisablePrevBotton(false);
            setInternalArray(matches, currentPageNum, 'forward', outerPageNumToInternalPageNumMap)
        }
        setPreviousPageNum(currentPageNum)
    }, [currentPageNum]);

    const applyFilters = async (matches, _filters) => {
        // setLoading(true);
        if (_filters === undefined) {
            _filters = filters;
        }
        var gender = _filters?.gender || undefined;
        var country = _filters?.country || undefined
        var from_year = _filters?.from_year || undefined
        var to_year = _filters?.to_year || undefined;
        var tags = _filters?.tags?.length === 0 ? undefined : _filters?.tags || undefined;
        var filtered_matches = []
        if (gender === undefined && country === undefined && to_year === undefined && from_year === undefined
            && tags === undefined) {
            // no filters

            //When clearing filtered set Matches same as unfiltered matches
            setMatches([...matches]);
            return matches;
        }
        matches.forEach(i => {
            if (country !== undefined) {
                if (i.country !== country) {
                    return;
                }
            }
            if (gender !== undefined) {
                if (i.gender !== gender) {
                    return;
                }
            }
            if (from_year !== undefined) {
                if (!(parseInt(from_year) <= parseInt(i.year) && parseInt(i.year) <= parseInt(to_year))) {
                    return;
                }
            }
            if (tags !== undefined) {
                var flag = false;
                i.tags.forEach(t => {
                    if (tags.includes(t)) {
                        flag = true;
                    }
                })
                if (flag === false) {
                    return;
                }
            }
            filtered_matches.push(i);
        })
        // console.log('filtered matches', filtered_matches);
        setMatches([...filtered_matches]);
        return filtered_matches;
    }

    const setPageMap = async (filtered_matches, page_map, outer_page_num, curr_page_num = currentPageNum) => {
        var temp = { ...page_map };
        var st = 0;
        var ed = recordsPerPage;
        var l = filtered_matches.length
        var sections = Math.floor(l / recordsPerPage);

        for (var i = 0; i < sections; i++) {
            var k = {}
            k[outer_page_num] = [st, ed];
            temp[curr_page_num + i] = k;
            st = ed;
            ed = ed + recordsPerPage;
        }

        setOuterPageNumToInternalPageNumMap(temp);
        return temp;
    }

    const setPageMapModulus = async (matches, st, internal_page_num, outer_page_num, page_map) => {
        var temp = { ...page_map };
        var ed = recordsPerPage + st;
        var l = matches.length - st;
        var sections = Math.floor(l / recordsPerPage);

        for (var i = 0; i < sections; i++) {
            var k = {}
            k[outer_page_num] = [st, ed];
            temp[internal_page_num + i] = k;
            st = ed;
            ed = ed + recordsPerPage;
        }
        var t_outer_page = getNextOuterPageNum(outer_page_num)
        if (t_outer_page === -1 && st !== matches.length && st < matches.length) {
            //next outer page does not exist and some data in array is being left to be indexed with next api call data
            var k = {};
            k[outer_page_num] = [st, matches.length];
            temp[internal_page_num + sections] = k;
        }

        setOuterPageNumToInternalPageNumMap(temp);
        return temp;
    }

    const getNextOuterPageNum = (page_num) => {
        if (totalOuterPages?.current !== undefined) {
            var next_page = parseInt(page_num) + 1;
            if (next_page <= totalOuterPages.current) {
                return next_page;
            }
            //return -1 if next page doesn't exist i.e. 
            //page_num is the last outerPageNum for current query
            // setDisableNextBotton(true);
            return -1;
        }
    }

    const disableNextButton = (outer_page_num, matches, end_index) => {
        var t = getNextOuterPageNum(outer_page_num)
        if (matches.length === end_index && t === -1) {
            setDisableNextBotton(true);
        }
        else {
            setDisableNextBotton(false);
        }
    }

    const setInternalArray = async (matches, current_page_num, movement, outerPageNumToInternalPageNumMap, _outerPageNum = outerPageNum) => {

       //console.log('in set internal array', current_page_num, movement, outerPageNumToInternalPageNumMap)
        
        if (outerPageNumToInternalPageNumMap.hasOwnProperty(current_page_num)) {
            // current internal page exists
            var outerPageToIndexMap = outerPageNumToInternalPageNumMap[current_page_num];
            if (Object.keys(outerPageToIndexMap).length === 1) {
               // console.log(Object.keys(outerPageToIndexMap)[0], 'hey hey hay');
                if (parseInt(Object.keys(outerPageToIndexMap)[0]) === _outerPageNum) {
                    // forward
                    var st = outerPageToIndexMap[_outerPageNum][0]
                    var ed = outerPageToIndexMap[_outerPageNum][1]
                    var data = matches.slice(st, ed)
                    setInternalMatchArray(data);
                    disableNextButton(_outerPageNum, matches, ed);
                } else {
                    var _key = parseInt(Object.keys(outerPageToIndexMap)[0]);
                    var _matches = await makeAPICall(_key, false)
                    var filtered_data = await applyFilters(_matches)
                    st = outerPageToIndexMap[_key][0]
                    ed = outerPageToIndexMap[_key][1]
                    var data = filtered_data.slice(st, ed)
                    setInternalMatchArray(data);
                    disableNextButton(_key, filtered_data, ed);
                }
            } else {
                // more than one outer pages on this page
                if (movement === 'forward') {
                    var data = []
                    var st = outerPageToIndexMap[_outerPageNum][0]
                    var ed = outerPageToIndexMap[_outerPageNum][1]
                    data.push(...matches.slice(st, ed))
                    for (var _key in outerPageToIndexMap) {
                        if (parseInt(_key) === _outerPageNum) {
                            continue;
                        }
                        var _matches = await makeAPICall(_key, false)
                        var filtered_data = await applyFilters(_matches)
                        st = outerPageToIndexMap[_key][0]
                        ed = outerPageToIndexMap[_key][1]
                        data.push(...filtered_data.slice(st, ed))
                        disableNextButton(_key, filtered_data, ed);
                    }
                    setInternalMatchArray(data);
                } else if (movement === 'backward') {
                    var data = []
                    var st;
                    var ed;
                    var last_data = [];
                    if (outerPageToIndexMap.hasOwnProperty(_outerPageNum)) {
                        // if current page num has some part from the current matches array then only it will contain key outerPageNum
                        st = outerPageToIndexMap[_outerPageNum][0]
                        ed = outerPageToIndexMap[_outerPageNum][1]
                        last_data = [...matches.slice(st, ed)]
                    }

                    for (var _key in outerPageToIndexMap) {
                        if (parseInt(_key) === _outerPageNum) {
                            continue;
                        }
                        var _matches = await makeAPICall(_key, false)
                        var filtered_data = await applyFilters(_matches)
                        st = outerPageToIndexMap[_key][0]
                        ed = outerPageToIndexMap[_key][1]
                        data.push(...filtered_data.slice(st, ed))
                        disableNextButton(_key, filtered_data, ed);
                    }

                    data.push(...last_data)
                    setInternalMatchArray(data);
                }
            }
        } else {
            //TODO; if current page hasn't been indexed yet- done
            //get last index of last page_num for current outer page
            var tempPageMap = { ...outerPageNumToInternalPageNumMap };
            var outerMap = {}
            var l = matches.length
            var match_array = []
            if (current_page_num === 1) {
                //no. of matches on 1st page are less than records per page
                if (l < recordsPerPage) {
                    outerMap[_outerPageNum] = [0, l];
                    match_array = [...matches];
                }
            } else {
                var last_index = outerPageNumToInternalPageNumMap[current_page_num - 1][_outerPageNum][1]
                if (last_index !== matches.length) {
                    outerMap[_outerPageNum] = [last_index, l];
                    match_array = [...matches.slice(last_index, l)];
                }
            }
            var next_page = _outerPageNum;
            while (true) {
                var prev_length = match_array.length
                next_page = getNextOuterPageNum(next_page)
                if (next_page === -1) {
                    setDisableNextBotton(true);
                    break;
                }
                var _matches = await makeAPICall(next_page, false)
                var filtered_data = await applyFilters(_matches)
                var new_length = filtered_data.length
                if (prev_length + new_length < recordsPerPage) {
                    //repeat call and all
                    prev_length = prev_length + new_length
                    outerMap[next_page] = [0, new_length];
                    match_array.push(...filtered_data);
                    var tnext_page = getNextOuterPageNum(next_page)
                    if (tnext_page === -1) {
                        setDisableNextBotton(true);
                        tempPageMap[current_page_num] = outerMap
                        break;
                    }
                } else {
                    var last_index = recordsPerPage - prev_length
                    outerMap[next_page] = [0, last_index];
                    match_array.push(...filtered_data.slice(0, last_index));
                    tempPageMap[current_page_num] = outerMap;
                    tempPageMap = await setPageMapModulus(filtered_data, last_index, current_page_num + 1, next_page, tempPageMap)
                    break;

                }
            }
            setInternalMatchArray(match_array)
            setOuterPageNumToInternalPageNumMap(tempPageMap);
        }
        setLoading(false);
        props.setLoading(false);
    }

    const makeAPICall = async (outerPageNum, resetPages) => {
        setLoading(true);
        var tempQuery = props.query;
        tempQuery.page_number = outerPageNum;
        if (props.searchByTemplateNameFlag === true) {
            var matches_ = await searchByTemplateName(props.templateName, outerPageNum)
        }
        else if(props.basicSearchFlag){
            for (var key in props.query) {
                if (props.query.hasOwnProperty(key)) {
                    var val = props.query[key];
                    if (val !== undefined) {
                        tempQuery[key] = val;
                    }
                }
            }
            var matches_ = await basicSearch(tempQuery)   
        }
        else {
            var matches_ = await queryBuilderSearch(tempQuery);
        }
        if (matches_ && matches_.status === 200) {
            // console.log('time');
            // await new Promise(r => setTimeout(r, 2000));
            // console.log('time');
            var data = matches_.data.detail
            if(props.basicSearchFlag){
                data = matches_.data
            }
            setMatches(data.results);
           // console.log("data----->",data.results);
            setUnfilteredMatches(data.results);
            // localStorage.setItem("basicSearchMatches", JSON.stringify(matches_.data.results));

            setNoOfMatches(data.number_of_matches);
            // localStorage.setItem("basicMatchesCount", matches_.data.number_of_matches);

            setouterPageNum(data.page_no);
            if(props.basicSearchFlag){
                setouterPageNum(data.page_number);
            }
            // localStorage.setItem("outerPageNum", matches_.data.page_number);

            // setTotalOuterPages(data.number_of_pages);
            totalOuterPages.current = data.number_of_pages
            // localStorage.setItem("totalouterpages", matches_.data.number_of_pages);

            // var t = Math.ceil(matches_.data.number_of_matches / recordsPerPage);
            // setTotalInternalPages(t);
            if (props.searchByTemplateNameFlag) {
                props.setsearchByTemplateQuery(data.query);
                
            }
                setCategory(parseInt(data.category)); 
                if(!props.basicSearchFlag){
                    props.setCategory(parseInt(data.category));
                }
        
            setTagOptions(data.tags_dict)

            if (resetPages) {
                setouterPageNum(data.page_no);
                if(props.basicSearchFlag){
                    setouterPageNum(data.page_number);
                }
                setCurrentPageNum(1);
                setPreviousPageNum(1);
            }
            // setLoading(false);
            return data.results;
        } else {
            setLoading(false);
            alert("An error occured");
            setNoOfMatches(0);
            return [];
        }

    }

    const formatTime = (hour, minute, second) => {
        return `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}:${("0" + second).slice(-2)}`;
    }

    const generateHoroscope = async (horoData,index,setIsLoading) => {
        console.log("horodata--",horoData);
        
        setIsLoading((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        let profileDetails = horoData;
       //console.log(profileDetails);
        let birthDetails = horoData;
        let p_name = profileDetails.first_name+' '+profileDetails.last_name;
        let horoscopeAPIData = await callHoroscopeAPI(p_name, profileDetails.gender, birthDetails.day, birthDetails.month, birthDetails.year, birthDetails.hour, birthDetails.minute, birthDetails.second, birthDetails.lat, birthDetails.long, birthDetails.offset, birthDetails.city,birthDetails.state, birthDetails.country, birthDetails.reflong, birthDetails.timezone);
        if (horoscopeAPIData) {
            let horoscopeAnalyseData = await callAnalyzeHoroscope(p_name, profileDetails.gender, birthDetails.day, birthDetails.month, birthDetails.year, birthDetails.hour, birthDetails.minute, birthDetails.second, birthDetails.lat, birthDetails.long, birthDetails.offset, birthDetails.city,birthDetails.state, birthDetails.country, birthDetails.reflong, birthDetails.timezone);
            //let requestparams = await callParamsHoroscope(cityId);
           // let requestparams = await callParamsHoroscope(2428015);
           let requestparams = {
            "name": p_name,
            "latitude": birthDetails.lat,
            "longitude": birthDetails.long,
            "country": birthDetails.country,
            "state": birthDetails.state,
            "timezone": birthDetails.timezone,
            "time": `${new Date().toISOString().replace('T', ' ').replace('Z', ' WITA+0800')}`,
            "offset": birthDetails.offset,
            "reference_longitude_for_timezone": birthDetails.reflong
        }
           // console.log("requestparams",requestparams);

            // get some random number of 4 digits 
            const randomNumber = Math.floor(1000 + Math.random() * 9000);

            const key_horoscope = `horoscopeData_${randomNumber}`;
            window.sessionStorage.setItem(key_horoscope, JSON.stringify(horoscopeAPIData));

            const key_analyser = `requestYogaAnalyzer_${randomNumber}`;
            window.sessionStorage.setItem(key_analyser, JSON.stringify(horoscopeAnalyseData));

            const key_params = `requestParamsHoroscope_${randomNumber}`;
            window.sessionStorage.setItem(key_params, JSON.stringify(requestparams));

             // history.push(`/horoscope/basicdetails?cb=${randomNumber}`);
             const newUrl = `/horoscope/basicdetails?cb=${randomNumber}`;
             setIsLoading((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
            // window.location.href = newUrl;
             window.open(newUrl, '_blank');

        }
    };

    const callHoroscopeAPI = async (name, gender, day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone) => {
        try {
            let ApiInstant = new Api();
            const result = await ApiInstant.fetchUserHoroscope(name, genderMapper[gender], day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone, localStorage.getItem("omp_token"));
            
            if (result.data) {
                // window.sessionStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
                 return result.data;
             }
        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };
    
    const callAnalyzeHoroscope = async (name, gender, day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone) => {
        try {
            let ApiYoga = new Api();
            const result = await ApiYoga.fetchAnalyzeHoroscopeAPI(name, genderMapper[gender], day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone, localStorage.getItem("omp_token"));
    
            if (result.data) {
               // window.sessionStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
                return result.data;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="eventWrapper m-1 pt-0">
                <hgroup class="mb20">
                    <h2 class="lead" style={{display:"flex"}}><strong class="text-danger">{(noOfMatches !== -1) ? (
                        <div><span className="match-count-span">{noOfMatches} matches found for the query above.</span>
                        </div>)  : ""}</strong> {internalMatchArray?.length === 0 && unfilteredMatches?.length === 0 ? "" : (
                        <Button style={{marginLeft:"20px"}} className="btn .btn-primary" onClick={() => setShowFilters(!showFilters)}>{showFilters ? "Hide Filters" : "Show Filters"}</Button>
                    )}</h2>
                        
                </hgroup>
                <div className="container">
                <div style={{ display: showFilters ? "block" : "none" }}>
                            <Filters
                                filterName={filterStorageKeyName}
                                tags={tagOptions}
                                setfilters={setFilters}
                                filters={filters}
                                // category={props?.category}
                                category={category}
                                basicSearchFlag={props.basicSearchFlag||false}
                            />
                        </div>
                    {(!loading) ? (internalMatchArray?.length === 0 ? (<>No Matches Found</>) : (
                        <>
                            {internalMatchArray.map((item,index) => {
                                return (
                                    <>
                                        <div class="col-xs-12 col-sm-6 col-md-12">
                                            <article class="search-result row">
                                                <div class="col-xs-12 col-sm-12 col-md-1">
                                                    <a href="#" title={item.first_name} class="thumbnail"><img
                                                        className="circular-square" src={aws_image_base + item.photo_name}
                                                        alt={item.first_name} /></a>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-3">
                                                    <ul class="meta-search">
                                                        <li><i class="fa fa-calendar"></i> <b>DOB
                                                            : </b><span> {item.day}/{item.month}/{item.year}, {formatTime(item.hour, item.minute, item.second)} </span>
                                                        </li>
                                                        <li><i class="fa fa-male" aria-hidden="true"></i><b> Gender
                                                            : </b><span>{item.gender}</span></li>
                                                        <li><i class="fa fa-map-marker"></i> <b>Birth Place
                                                            : </b><span>{item.city}, {item.state}, {item.country}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-7 excerpet">
                                                    <h3>
                                                        {/* <a href="#" key={item.o_id}
                                                       onClick={() => openHorscope(item.o_id, item.collection_code)}>{item.first_name} {item.last_name}</a> */}
                                                        <Link
                                                            to={`/celebrity/show?i=${item.o_id}&collCode=${item.collection_code}`}
                                                            target="_blank">{item.first_name} {item.last_name}</Link>
                                                    </h3>
                                                    <p>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: item.biography.substring(0, 200) + "..." }}></div>
                                                    </p>
                                                    <button className="btn btn-sm btn-primary mt-1" onClick={()=>generateHoroscope(item,index,setIsLoading)}>
                                         {(isLoading[index]) ? (
                          <span>Generating horoscope ...
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (<span> View horoscope</span>)}
                                         </button>
                                                </div>
                                                <span class="clearfix borda"></span>
                                            </article>
                                            <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />

                                        </div>
                                    </>

                                )
                            })}
                            <div className="row" style={{textAlign:"center"}}>
                                <div className="col" style={{textAlign:"right"}}> 
                                <button disabled={disablePrevBotton || loading || currentPageNum===1}
                                    className='btn btn-sm btn-success'
                                    onClick={() => setCurrentPageNum(currentPageNum - 1)}>
                                    prev({currentPageNum - 1})
                                </button>
                                </div>
                                <div className="col" style={{display:"contents"}}>
                                    <span style={{paddingTop:"8px"}}>
                                    Page Number: {currentPageNum}
                                    </span>
                                    </div>
                                <div className="col" style={{textAlign:"left"}}><button className='btn btn-sm btn-success' disabled={disableNextBotton || loading}
                                    onClick={() => setCurrentPageNum(currentPageNum + 1)}>
                                    next({currentPageNum + 1})
                                </button></div>

                            </div>
                        </>

                    )) : (
                        <center className="pt-4 pb-4">
                            <p>Quering... it may take some time....</p>
                            <Spinner animation="border" variant="primary" />
                        </center>
                    )}
                </div>
            </div>
        </>
    );
}

export default withRouter(DisplayResults);