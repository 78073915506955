import React, { Component } from "react";
import Plotly from "plotly.js";
import moment from "moment";
import { GlobalContext } from "../../../mycontext";
import "./plotly.css";
import sideform from "../../SideComponents/sideform";
import DashboardTopBar from "../../DashBoardComponents/DashboardTopBar";
import DashboardSideBar from "../../DashBoardComponents/DashboardSideBar";
import DashboardFooter from "../../DashBoardComponents/DashboardFooter";
import Sideform from "../../SideComponents/sideform";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
class Plotly_NAKS extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    const { t, i18n } = this.props;
    this.state = {
      view_type: "rashi",
      highlighted: 1,
      showResetForm:false,
    };
  }

  componentDidMount() {
    let api = JSON.parse(window.localStorage.getItem("longTermJourneyAPIData"));
    if (
      api !== null &&
      this.context.longTermJourneyAPIData === null
    ) {
      this.context.setLongTermJourneyAPIData(api);
    }
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParams")
    );
    if (
      requestParams !== null &&
      Object.keys(this.context.requestParams).length === 0
    ) {
      this.context.setRequestParams(requestParams);
    }
    let start = new Date(window.localStorage.getItem("longTermJourneyStartDate"));
    if (start !== null && this.context.longTermJourneyStartDate === null) {
      this.context.setlongTermJourneyStartorEndDate( "Start", start);
    }
    let end = new Date(window.localStorage.getItem("longTermJourneyEndDate"));
    if (end !== null && this.context.longTermJourneyEndDate === null) {
      this.context.setlongTermJourneyStartorEndDate("End", end);
    }
    let planet = window.localStorage.getItem("longTermJourneyPlanet")
    if (planet !== null && this.context.longTermJourneyPlanet === null) {
      this.context.setLongTermJourneyPlanet(planet);
    }
    if (
      requestParams === null ||
      api === null ||
      start === null ||
      end === null ||
      planet===null
    ) {
      this.props.history.push("/dashboard");
    } else {
      this.apidate_format_rashi(api.traces_view_data);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      // this.props.end !== prevProps.end ||
      // this.props.start !== prevProps.start ||
      // this.props.city !== prevProps.city ||
      // this.props.selectedLanguage !== prevProps.selectedLanguage
      this.props.updatetoggle !== prevProps.updatetoggle
    )
      this.apidate_format_rashi(this.context.longTermJourneyAPIData.traces_view_data);
  }

  apidate_format_nakshatra = (t) => {
    var gd1 = document.getElementById("myDiv_NAKS");
    var api_data = this.context.longTermJourneyAPIData.traces_view_data;
    // console.log(api_data);
    this.view_name = "Nakshatra";

    // var from_year = api_data.from_year;
    // var from_month = api_data.from_month;
    // var from_day =api_data.from_day;
    // var to_year = api_data.to_year;
    // var to_month = api_data.to_month;
    // var to_day = api_data.to_day;

    var x_dates = [];
    var y_planet0 = [];
    var y_planet1 = [];
    var y_planet2 = [];
    var y_planet3 = [];
    var y_planet4 = [];
    var y_planet5 = [];
    var y_planet6 = [];
    var y_planet7 = [];
    var y_planet8 = [];

    for (let i = 0; i < api_data.p_dates.length; i++) {
      const api_date = api_data.p_dates[i].date;
      const planet_sun = api_data.p_dates[i].planets[0].deg;
      // const planet_moon = api_data.p_dates[i].planets[1].deg;
      // const planet_mercury = api_data.p_dates[i].planets[2].deg;
      // const planet_venus = api_data.p_dates[i].planets[3].deg;
      // const planet_mars = api_data.p_dates[i].planets[4].deg;
      // const planet_jupiter = api_data.p_dates[i].planets[5].deg;
      // const planet_saturn = api_data.p_dates[i].planets[6].deg;
      // const planet_rahu = api_data.p_dates[i].planets[7].deg;
      // const planet_ketu = api_data.p_dates[i].planets[8].deg;

      var moment_obj = moment(
        JSON.parse(JSON.stringify(api_date)),
        "DD-MM-YYYY"
      );

      var date = moment(moment_obj).format("YYYY-MM-DD");
      x_dates.push(date);
      y_planet0.push(JSON.parse(JSON.stringify(planet_sun)));
      // y_planet1.push(JSON.parse(JSON.stringify(planet_moon)));
      // y_planet2.push(JSON.parse(JSON.stringify(planet_mercury)));
      // y_planet3.push(JSON.parse(JSON.stringify(planet_venus)));
      // y_planet4.push(JSON.parse(JSON.stringify(planet_mars)));
      // y_planet5.push(JSON.parse(JSON.stringify(planet_jupiter)));
      // y_planet6.push(JSON.parse(JSON.stringify(planet_saturn)));
      // y_planet7.push(JSON.parse(JSON.stringify(planet_rahu)));
      // y_planet8.push(JSON.parse(JSON.stringify(planet_ketu)));
    }
    //console.log("Superman -"+ x_dates+ "- deg-" + y_planet1[0]);
    var maxDate = x_dates[x_dates.length - 1];
    var minDate = x_dates[0];
    // var minDate_ = moment(x_dates[0],'DD-MM-YYYY');
    // var minDate = moment(minDate_).format('YYYY-MM-DD');
    // var maxDate_ = moment(date_end,'DD-MM-YYYY');
    // var maxDate = moment(maxDate_).format('YYYY-MM-DD');

    // console.log("Dates " + minDate + "-" + (date_end) +"---- " + maxDate);

    var sun_data = {
      x: x_dates,
      y: y_planet0,
      name: this.props.t(this.context.longTermJourneyPlanet),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#FFE05D",
      },
    };
    // var moon_data = {
    //   x: x_dates,
    //   y: y_planet1,
    //   name: this.props.t("Moon"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#00BCD4",
    //   },
    // };

    // var mercury_data = {
    //   x: x_dates,
    //   y: y_planet2,
    //   name: this.props.t("Mercury"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#335D2D",
    //   },
    // };
    // var venus_data = {
    //   x: x_dates,
    //   y: y_planet3,
    //   name: this.props.t("Venus"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#F4ABC4",
    //   },
    // };
    // var mars_data = {
    //   x: x_dates,
    //   y: y_planet4,
    //   name: this.props.t("Mars"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#DD2C00",
    //   },
    // };
    // var jupiter_data = {
    //   x: x_dates,
    //   y: y_planet5,
    //   name: this.props.t("Jupiter"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#F6830F",
    //   },
    // };
    // var saturn_data = {
    //   x: x_dates,
    //   y: y_planet6,
    //   name: this.props.t("Saturn"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#005086",
    //   },
    // };
    // var rahu_data = {
    //   x: x_dates,
    //   y: y_planet7,
    //   name: this.props.t("Rahu"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#322F3D",
    //   },
    // };
    // var ketu_data = {
    //   x: x_dates,
    //   y: y_planet8,
    //   name: this.props.t("Ketu"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#4B5D67",
    //   },
    // };

    var trace2 = {
      x: [
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
      ],
      y: [
        6.66, 19.99, 33.32, 46.65, 59.98, 73.31, 86.64, 99.9, 113.3, 126.63,
        139.96, 153.29, 166.62, 179.95, 193.28, 206.61, 219.94, 233.27, 246.605,
        259.93, 273.26, 286.59, 299.92, 313.255, 326.58, 339.91, 353.245,
      ],
      name: this.props.t("Nakshatras"),
      yaxis: "y2",
      type: "scatter",
    };

    var layout = {
      showlegend: true,
      legend: { orientation: "h", x: 0.01, y: 1.05 },
      autosize: true,
      // width:1080,
      // height:720,
      title: this.props.t("Nakshatra View"),
      hovermode: "x|y",
      hoverdistance: 100,
      spikedistance: 1000,

      xaxis: {
        title: this.props.t("Dates"),
        range: [minDate, maxDate],
        type: "date",
        showgrid: false,
        // zeroline:false,
        showticklabels: true,
        showspikes: true,
        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "#999999",
        spikemode: "across",

        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickangle: 38,
        rangemode: "tozero",
        autorange: true,
        ticks: "outside",
        tickformat: "%Y~%m~%d",
      },

      yaxis: {
        showgrid: false,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "#efefef",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        tickmode: "array",
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
      },
      yaxis2: {
        showgrid: false,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "#efefef",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 9,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        overlaying: "y1",
        side: "right",
        //tickmode : 'array',
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [
          6.66, 19.99, 33.32, 46.65, 59.98, 73.31, 86.64, 99.9, 113.3, 126.63,
          139.96, 153.29, 166.62, 179.95, 193.28, 206.61, 219.94, 233.27,
          246.605, 259.93, 273.26, 286.59, 299.92, 313.255, 326.58, 339.91,
          353.245,
        ],
        ticktext: [
          this.props.t("Ashwini"),
          this.props.t("Bharani"),
          this.props.t("Krittika"),
          this.props.t("Rohini"),
          this.props.t("Mrigashirsha"),
          this.props.t("Ardra"),
          this.props.t("Punarvasu"),
          this.props.t("Pushya"),
          this.props.t("Ashlesha"),
          this.props.t("Magha"),
          this.props.t("Purva Phalguni"),
          this.props.t("Uttara Phalguni"),
          this.props.t("Hasta"),
          this.props.t("Chitra"),
          this.props.t("Swati"),
          this.props.t("Visakha"),
          this.props.t("Anuradha"),
          this.props.t("Jyeshtha"),
          this.props.t("Mula"),
          this.props.t("Purva Ashadha"),
          this.props.t("Uttara Ashadha"),
          this.props.t("Sravana"),
          this.props.t("Dhanishta"),
          this.props.t("Shatbhisha"),
          this.props.t("Purva Bhadrapada"),
          this.props.t("Uttara Bhadrapada"),
          this.props.t("Revathi"),
        ],
      },

      shapes: [
        // 1st highlight during Feb 4 - Feb 6
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0], //1-10-2020 (1-9-2020)
          y0: 0,
          x1: x_dates[x_dates.length - 1],
          y1: 13.66,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 13.66,
          x1: x_dates[x_dates.length - 1],
          y1: 26.66,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 26.66,
          x1: x_dates[x_dates.length - 1],
          y1: 39.99,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 39.99,
          x1: x_dates[x_dates.length - 1],
          y1: 53.32,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 53.32,
          x1: x_dates[x_dates.length - 1],
          y1: 66.65,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 66.65,
          x1: x_dates[x_dates.length - 1],
          y1: 79.98,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 79.98,
          x1: x_dates[x_dates.length - 1],
          y1: 93.31,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 93.31,
          x1: x_dates[x_dates.length - 1],
          y1: 106.64,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },

        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 106.64,
          x1: x_dates[x_dates.length - 1],
          y1: 119.97,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 119.97,
          x1: x_dates[x_dates.length - 1],
          y1: 133.3,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 133.3,
          x1: x_dates[x_dates.length - 1],
          y1: 146.63,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 146.63,
          x1: x_dates[x_dates.length - 1],
          y1: 159.96,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 159.96,
          x1: x_dates[x_dates.length - 1],
          y1: 173.29,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 173.29,
          x1: x_dates[x_dates.length - 1],
          y1: 186.62,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 182.62,
          x1: x_dates[x_dates.length - 1],
          y1: 199.95,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 199.95,
          x1: x_dates[x_dates.length - 1],
          y1: 213.28,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        // y:[226.61,239.94,253.27,266.6,279.93,293.26,306.59,319.92,333.25,346.58,359.91],
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 213.28,
          x1: x_dates[x_dates.length - 1],
          y1: 226.61,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 226.61,
          x1: x_dates[x_dates.length - 1],
          y1: 239.94,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 239.94,
          x1: x_dates[x_dates.length - 1],
          y1: 253.27,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 253.27,
          x1: x_dates[x_dates.length - 1],
          y1: 266.6,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 266.6,
          x1: x_dates[x_dates.length - 1],
          y1: 279.93,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 279.93,
          x1: x_dates[x_dates.length - 1],
          y1: 293.26,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 293.26,
          x1: x_dates[x_dates.length - 1],
          y1: 306.59,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 306.59,
          x1: x_dates[x_dates.length - 1],
          y1: 319.92,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 319.92,
          x1: x_dates[x_dates.length - 1],
          y1: 333.25,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 333.25,
          x1: x_dates[x_dates.length - 1],
          y1: 346.58,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 346.58,
          x1: x_dates[x_dates.length - 1],
          y1: 359.91,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 359.91,
          x1: x_dates[x_dates.length - 1],
          y1: 372,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
      ],
    };

    var data = [
      sun_data,
      // moon_data,
      // mercury_data,
      // venus_data,
      // mars_data,
      // jupiter_data,
      // saturn_data,
      // rahu_data,
      // ketu_data,
      trace2,
    ];
    var config = { responsive: true, displaylogo: false };

    Plotly.newPlot(gd1, data, layout, config);
  };

  apidate_format_rashi = (api, t) => {
    var gd1 = document.getElementById("myDiv_NAKS");
    var api_data = api;
    this.view_name = this.props.t("Rashi");

    // var from_year = api_data.from_year;
    // var from_month = api_data.from_month;
    // var from_day =api_data.from_day;
    // var to_year = api_data.to_year;
    // var to_month = api_data.to_month;
    // var to_day = api_data.to_day;

    var x_dates = [];
    var y_planet0 = [];
    var y_planet1 = [];
    var y_planet2 = [];
    var y_planet3 = [];
    var y_planet4 = [];
    var y_planet5 = [];
    var y_planet6 = [];
    var y_planet7 = [];
    var y_planet8 = [];

    for (let i = 0; i < api_data.p_dates.length; i++) {
      const api_date = api_data.p_dates[i].date;
      const planet_sun = api_data.p_dates[i].planets[0].deg;
      // const planet_moon = api_data.p_dates[i].planets[1].deg;
      // const planet_mercury = api_data.p_dates[i].planets[2].deg;
      // const planet_venus = api_data.p_dates[i].planets[3].deg;
      // const planet_mars = api_data.p_dates[i].planets[4].deg;
      // const planet_jupiter = api_data.p_dates[i].planets[5].deg;
      // const planet_saturn = api_data.p_dates[i].planets[6].deg;
      // const planet_rahu = api_data.p_dates[i].planets[7].deg;
      // const planet_ketu = api_data.p_dates[i].planets[8].deg;

      var moment_obj = moment(
        JSON.parse(JSON.stringify(api_date)),
        "DD-MM-YYYY"
      );
      var date = moment(moment_obj).format("YYYY-MM-DD");
      x_dates.push(date);
      y_planet0.push(JSON.parse(JSON.stringify(planet_sun)));
      // y_planet1.push(JSON.parse(JSON.stringify(planet_moon)));
      // y_planet2.push(JSON.parse(JSON.stringify(planet_mercury)));
      // y_planet3.push(JSON.parse(JSON.stringify(planet_venus)));
      // y_planet4.push(JSON.parse(JSON.stringify(planet_mars)));
      // y_planet5.push(JSON.parse(JSON.stringify(planet_jupiter)));
      // y_planet6.push(JSON.parse(JSON.stringify(planet_saturn)));
      // y_planet7.push(JSON.parse(JSON.stringify(planet_rahu)));
      // y_planet8.push(JSON.parse(JSON.stringify(planet_ketu)));
    }
    // console.log("Superman -"+ x_dates+ "- deg-" + y_planet1[0]);
    // var date_end = x_dates[x_dates.length-1];
    // var minDate_ = moment(x_dates[0],'DD-MM-YYYY');
    // var minDate = moment(minDate_).format('YYYY-MM-DD');
    // var maxDate_ = moment(date_end,'DD-MM-YYYY');
    // var maxDate = moment(maxDate_).format('YYYY-MM-DD');

    var maxDate = x_dates[x_dates.length - 1];
    var minDate = x_dates[0];

    // console.log("Dates " + minDate + "-" + (date_end) +"---- " + maxDate);

    var sun_data = {
      x: x_dates,
      y: y_planet0,
      name: this.props.t(this.context.longTermJourneyPlanet),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#FFE05D",
      },
    };
    // var moon_data = {
    //   x: x_dates,
    //   y: y_planet1,
    //   name: this.props.t("Moon"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#00BCD4",
    //   },
    // };

    // var mercury_data = {
    //   x: x_dates,
    //   y: y_planet2,
    //   name: this.props.t("Mercury"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#335D2D",
    //   },
    // };
    // var venus_data = {
    //   x: x_dates,
    //   y: y_planet3,
    //   name: this.props.t("Venus"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#F4ABC4",
    //   },
    // };
    // var mars_data = {
    //   x: x_dates,
    //   y: y_planet4,
    //   name: this.props.t("Mars"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#DD2C00",
    //   },
    // };
    // var jupiter_data = {
    //   x: x_dates,
    //   y: y_planet5,
    //   name: this.props.t("Jupiter"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#F6830F",
    //   },
    // };
    // var saturn_data = {
    //   x: x_dates,
    //   y: y_planet6,
    //   name: this.props.t("Saturn"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#005086",
    //   },
    // };
    // var rahu_data = {
    //   x: x_dates,
    //   y: y_planet7,
    //   name: this.props.t("Rahu"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#322F3D",
    //   },
    // };
    // var ketu_data = {
    //   x: x_dates,
    //   y: y_planet8,
    //   name: this.props.t("Ketu"),
    //   type: "scatter",
    //   mode: "lines",
    //   line: {
    //     color: "#4B5D67",
    //   },
    // };

    var trace2 = {
      x: [
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
      ],
      y: [15, 45, 75, 105, 135, 165, 195, 225, 255, 285, 315, 345],
      name: this.props.t("Rashi"),
      yaxis: "y2",
      type: "scatter",
    };

    var layout = {
      showlegend: true,
      legend: { orientation: "h", x: 0.01, y: 1.04 },
      autosize: true,
      // width:1080,
      // height:720,
      title: this.props.t("Rashi View"),
      hovermode: "x|y",
      hoverdistance: 100,
      spikedistance: 1000,

      xaxis: {
        title: this.props.t("Dates"),
        range: [minDate, maxDate],
        type: "date",
        showgrid: false,
        zeroline:false,
        showticklabels: true,
        showspikes: true,
        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "#999999",
        spikemode: "across",

        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickangle: 38,
        rangemode: "tozero",
        autorange: true,
        ticks: "outside",
        tickformat: "%Y~%m~%d",
      },

      yaxis: {
        showgrid: false,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "#efefef",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        tickmode: "array",
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
      },
      yaxis2: {
        showgrid: false,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "#efefef",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        tickmode: "array",
        overlaying: "y1",
        side: "right",
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [15, 45, 75, 105, 135, 165, 195, 225, 255, 285, 315, 345],
        ticktext: [
          this.props.t("Aries"),
          this.props.t("Taurus"),
          this.props.t("Gemini"),
          this.props.t("Cancer"),
          this.props.t("Leo"),
          this.props.t("Virgo"),
          this.props.t("Libra"),
          this.props.t("Scorpio"),
          this.props.t("Sagittarius"),
          this.props.t("Capricorn"),
          this.props.t("Aquarius"),
          this.props.t("Pisces"),
        ],
      },
      shapes: [
        // 1st highlight during Feb 4 - Feb 6
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0], //1-10-2020 (1-9-2020)
          y0: 0,
          x1: x_dates[x_dates.length - 1],
          y1: 30,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 30,
          x1: x_dates[x_dates.length - 1],
          y1: 60,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 60,
          x1: x_dates[x_dates.length - 1],
          y1: 90,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 90,
          x1: x_dates[x_dates.length - 1],
          y1: 120,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 120,
          x1: x_dates[x_dates.length - 1],
          y1: 150,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 150,
          x1: x_dates[x_dates.length - 1],
          y1: 180,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 180,
          x1: x_dates[x_dates.length - 1],
          y1: 210,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 210,
          x1: x_dates[x_dates.length - 1],
          y1: 240,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 240,
          x1: x_dates[x_dates.length - 1],
          y1: 270,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 270,
          x1: x_dates[x_dates.length - 1],
          y1: 300,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 300,
          x1: x_dates[x_dates.length - 1],
          y1: 330,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 330,
          x1: x_dates[x_dates.length - 1],
          y1: 360,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
      ],
    };

    var data = [
      sun_data,
      // moon_data,
      // mercury_data,
      // venus_data,
      // mars_data,
      // jupiter_data,
      // saturn_data,
      // rahu_data,
      // ketu_data,
      trace2,
    ];
    var config = { responsive: true, displaylogo: false };

    Plotly.newPlot(gd1, data, layout, config);
  };

  apidate_format_combined = (t) => {
    var gd1 = document.getElementById("myDiv_NAKS");
    var api_data = this.context.longTermJourneyAPIData.traces_view_data;
    this.view_name = "combined";

    // var from_year = api_data.from_year;
    // var from_month = api_data.from_month;
    // var from_day =api_data.from_day;
    // var to_year = api_data.to_year;
    // var to_month = api_data.to_month;
    // var to_day = api_data.to_day;

    var x_dates = [];
    var y_planet0 = [];
    var y_planet1 = [];
    var y_planet2 = [];
    var y_planet3 = [];
    var y_planet4 = [];
    var y_planet5 = [];
    var y_planet6 = [];
    var y_planet7 = [];
    var y_planet8 = [];

    for (let i = 0; i < api_data.p_dates.length; i++) {
      const api_date = api_data.p_dates[i].date;
      const planet_sun = api_data.p_dates[i].planets[0].deg;
      // const planet_moon = api_data.p_dates[i].planets[1].deg;
      // const planet_mercury = api_data.p_dates[i].planets[2].deg;
      // const planet_venus = api_data.p_dates[i].planets[3].deg;
      // const planet_mars = api_data.p_dates[i].planets[4].deg;
      // const planet_jupiter = api_data.p_dates[i].planets[5].deg;
      // const planet_saturn = api_data.p_dates[i].planets[6].deg;
      // const planet_rahu = api_data.p_dates[i].planets[7].deg;
      // const planet_ketu = api_data.p_dates[i].planets[8].deg;

      var moment_obj = moment(
        JSON.parse(JSON.stringify(api_date)),
        "DD-MM-YYYY"
      );
      var date = moment(moment_obj).format("YYYY-MM-DD");
      x_dates.push(date);
      y_planet0.push(JSON.parse(JSON.stringify(planet_sun)));
      // y_planet1.push(JSON.parse(JSON.stringify(planet_moon)));
      // y_planet2.push(JSON.parse(JSON.stringify(planet_mercury)));
      // y_planet3.push(JSON.parse(JSON.stringify(planet_venus)));
      // y_planet4.push(JSON.parse(JSON.stringify(planet_mars)));
      // y_planet5.push(JSON.parse(JSON.stringify(planet_jupiter)));
      // y_planet6.push(JSON.parse(JSON.stringify(planet_saturn)));
      // y_planet7.push(JSON.parse(JSON.stringify(planet_rahu)));
      // y_planet8.push(JSON.parse(JSON.stringify(planet_ketu)));
    }
    // console.log("Superman -"+ x_dates+ "- deg-" + y_planet1[0]);
    // var date_end = x_dates[x_dates.length-1];
    // var minDate_ = moment(x_dates[0],'DD-MM-YYYY');
    // var minDate = moment(minDate_).format('YYYY-MM-DD');
    // var maxDate_ = moment(date_end,'DD-MM-YYYY');
    // var maxDate = moment(maxDate_).format('YYYY-MM-DD');

    var maxDate = x_dates[x_dates.length - 1];
    var minDate = x_dates[0];

    // console.log("Dates " + minDate + "-" + (date_end) +"---- " + maxDate);

    var sun_data = {
      x: x_dates,
      y: y_planet0,
      name: this.props.t(this.context.longTermJourneyPlanet),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#FFE05D",
      },
    };
    var moon_data = {
      x: x_dates,
      y: y_planet1,
      name: this.props.t("Moon"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#00BCD4",
      },
    };

    var mercury_data = {
      x: x_dates,
      y: y_planet2,
      name: this.props.t("Mercury"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#335D2D",
      },
    };
    var venus_data = {
      x: x_dates,
      y: y_planet3,
      name: this.props.t("Venus"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#F4ABC4",
      },
    };
    var mars_data = {
      x: x_dates,
      y: y_planet4,
      name: this.props.t("Mars"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#DD2C00",
      },
    };
    var jupiter_data = {
      x: x_dates,
      y: y_planet5,
      name: this.props.t("Jupiter"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#F6830F",
      },
    };
    var saturn_data = {
      x: x_dates,
      y: y_planet6,
      name: this.props.t("Saturn"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#005086",
      },
    };
    var rahu_data = {
      x: x_dates,
      y: y_planet7,
      name: this.props.t("Rahu"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#322F3D",
      },
    };
    var ketu_data = {
      x: x_dates,
      y: y_planet8,
      name: this.props.t("Ketu"),
      type: "scatter",
      mode: "lines",
      line: {
        color: "#4B5D67",
      },
    };

    var trace2 = {
      x: [
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
        x_dates[x_dates.length],
      ],
      y: [
        6.66, 19.99, 33.32, 46.65, 59.98, 73.31, 86.64, 99.9, 113.3, 126.63,
        139.96, 153.29, 166.62, 179.95, 193.28, 206.61, 219.94, 233.27, 246.605,
        259.93, 273.26, 286.59, 299.92, 313.255, 326.58, 339.91, 353.245,
      ],
      name: this.props.t("Nakshatras"),
      yaxis: "y2",
      type: "scatter",
    };

    var layout = {
      showlegend: true,
      legend: { orientation: "h", x: 0.01, y: 1.05 },
      autosize: true,
      title: this.props.t("Combined View"),
      hovermode: "x|y",
      hoverdistance: 100,
      spikedistance: 1000,

      xaxis: {
        title: this.props.t("Dates"),
        range: [minDate, maxDate],
        type: "date",
        showgrid: false,
        // zeroline:false,
        showticklabels: true,
        showspikes: true,
        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "#999999",
        spikemode: "across",

        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickangle: 38,
        rangemode: "tozero",
        autorange: true,
        ticks: "outside",
        tickformat: "%Y~%m~%d",
      },

      yaxis: {
        showgrid: true,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "silver",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        tickmode: "array",
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
        ticktext: [
          this.props.t("Aries"),
          this.props.t("Taurus"),
          this.props.t("Gemini"),
          this.props.t("Cancer"),
          this.props.t("Leo"),
          this.props.t("Virgo"),
          this.props.t("Libra"),
          this.props.t("Scorpio"),
          this.props.t("Sagittarius"),
          this.props.t("Capricorn"),
          this.props.t("Aquarius"),
          this.props.t("Pisces"),
        ],
      },
      yaxis2: {
        showgrid: false,
        // zeroline:false,
        showspikes: true,

        spikethickness: 1,
        spikedash: "dot",
        spikecolor: "red",
        spikemode: "across",
        gridcolor: "#efefef",
        showticklabels: true,
        linecolor: "rgb(204, 204, 204)",
        linewidth: 2,
        tickfont: {
          family: "Arial",
          size: 9,
          color: "rgb(82, 82, 82)",
        },
        rangemode: "nonnegative",
        autorange: true,
        ticks: "outside",
        overlaying: "y1",
        side: "right",
        tickmode: "array",
        // tickangle: 45, rangemode: 'nonnegative',
        tickvals: [
          6.66, 19.99, 33.32, 46.65, 59.98, 73.31, 86.64, 99.9, 113.3, 126.63,
          139.96, 153.29, 166.62, 179.95, 193.28, 206.61, 219.94, 233.27,
          246.605, 259.93, 273.26, 286.59, 299.92, 313.255, 326.58, 339.91,
          353.245,
        ],
        ticktext: [
          this.props.t("Ashwini"),
          this.props.t("Bharani"),
          this.props.t("Krittika"),
          this.props.t("Rohini"),
          this.props.t("Mrigashirsha"),
          this.props.t("Ardra"),
          this.props.t("Punarvasu"),
          this.props.t("Pushya"),
          this.props.t("Ashlesha"),
          this.props.t("Magha"),
          this.props.t("Purva Phalguni"),
          this.props.t("Uttara Phalguni"),
          this.props.t("Hasta"),
          this.props.t("Chitra"),
          this.props.t("Swati"),
          this.props.t("Visakha"),
          this.props.t("Anuradha"),
          this.props.t("Jyeshtha"),
          this.props.t("Mula"),
          this.props.t("Purva Ashadha"),
          this.props.t("Uttara Ashadha"),
          this.props.t("Sravana"),
          this.props.t("Dhanishta"),
          this.props.t("Shatbhisha"),
          this.props.t("Purva Bhadrapada"),
          this.props.t("Uttara Bhadrapada"),
          this.props.t("Revathi"),
        ],
      },
      shapes: [
        // 1st highlight during Feb 4 - Feb 6
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0], //1-10-2020 (1-9-2020)
          y0: 0,
          x1: x_dates[x_dates.length - 1],
          y1: 13.66,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 13.66,
          x1: x_dates[x_dates.length - 1],
          y1: 26.66,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 26.66,
          x1: x_dates[x_dates.length - 1],
          y1: 39.99,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 39.99,
          x1: x_dates[x_dates.length - 1],
          y1: 53.32,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 53.32,
          x1: x_dates[x_dates.length - 1],
          y1: 66.65,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 66.65,
          x1: x_dates[x_dates.length - 1],
          y1: 79.98,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 79.98,
          x1: x_dates[x_dates.length - 1],
          y1: 93.31,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 93.31,
          x1: x_dates[x_dates.length - 1],
          y1: 106.64,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },

        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 106.64,
          x1: x_dates[x_dates.length - 1],
          y1: 119.97,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 119.97,
          x1: x_dates[x_dates.length - 1],
          y1: 133.3,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 133.3,
          x1: x_dates[x_dates.length - 1],
          y1: 146.63,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 146.63,
          x1: x_dates[x_dates.length - 1],
          y1: 159.96,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 159.96,
          x1: x_dates[x_dates.length - 1],
          y1: 173.29,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 173.29,
          x1: x_dates[x_dates.length - 1],
          y1: 186.62,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 182.62,
          x1: x_dates[x_dates.length - 1],
          y1: 199.95,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 199.95,
          x1: x_dates[x_dates.length - 1],
          y1: 213.28,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        // y:[226.61,239.94,253.27,266.6,279.93,293.26,306.59,319.92,333.25,346.58,359.91],
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 213.28,
          x1: x_dates[x_dates.length - 1],
          y1: 226.61,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 226.61,
          x1: x_dates[x_dates.length - 1],
          y1: 239.94,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 239.94,
          x1: x_dates[x_dates.length - 1],
          y1: 253.27,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 253.27,
          x1: x_dates[x_dates.length - 1],
          y1: 266.6,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 266.6,
          x1: x_dates[x_dates.length - 1],
          y1: 279.93,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 279.93,
          x1: x_dates[x_dates.length - 1],
          y1: 293.26,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 293.26,
          x1: x_dates[x_dates.length - 1],
          y1: 306.59,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 306.59,
          x1: x_dates[x_dates.length - 1],
          y1: 319.92,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 319.92,
          x1: x_dates[x_dates.length - 1],
          y1: 333.25,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 333.25,
          x1: x_dates[x_dates.length - 1],
          y1: 346.58,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 346.58,
          x1: x_dates[x_dates.length - 1],
          y1: 359.91,
          fillcolor: "#fff",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
        {
          type: "rect",
          xref: "x",
          yref: "y",
          x0: x_dates[0],
          y0: 359.91,
          x1: x_dates[x_dates.length - 1],
          y1: 372,
          fillcolor: "#F3F7F9",
          //opacity:0.9,
          layer: "below",
          line: {
            width: 0,
          },
        },
      ],
    };

    var data = [
      sun_data,
      // moon_data,
      // mercury_data,
      // venus_data,
      // mars_data,
      // jupiter_data,
      // saturn_data,
      // rahu_data,
      // ketu_data,
      trace2,
    ];
    var config = { responsive: true, displaylogo: false };

    Plotly.newPlot(gd1, data, layout, config);
  };

  switch_rasiview = (t) => {
    this.setState({ view_type: "rasi", highlighted: 1 });
    this.apidate_format_rashi(this.context.longTermJourneyAPIData.traces_view_data, t);
  };
  switch_combinedview = (t) => {
    this.setState({ view_type: "combined", highlighted: 3 });
    this.apidate_format_combined(t);
  };
  switch_naksview = (t) => {
    this.setState({ view_type: "naks", highlighted: 2 });
    this.apidate_format_nakshatra(t);
  };

  toggleClass = () => {
    this.state.currentClass === "col-lg-10 col-md-12"
      ? this.setState({ currentClass: "fullscreen" })
      : this.setState({ currentClass: "col-lg-10 col-md-12" });
  };

  resetForm() {
    if (Object.keys(this.context.requestParams.length !== 0)) {
      if (
        this.context.transitCountry === null ||
        this.context.transitCountry === ""
      ) {
        this.context.setCountry(this.context.requestParams.country, "Transit");
      }
      if (
        this.context.transitCityState === null ||
        this.context.transitCityState === ""
      )
        this.context.setCityAndState(
          `${this.context.requestParams.name},${this.context.requestParams.state}`,
          "Transit"
        );
    }
    // this.context.handleSideFormTransition(true);
    this.setState({showResetForm:true})
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Sideform
          // show={this.context.showSideFormTransition}
          mode="TransitionView"
          view="tracesView"
          // handleClose={this.context.handleSideFormTransition}
          show={this.state.showResetForm}
          handleClose={i=>this.setState({showResetForm:false})}
          reset={true}
          history={this.props.history}
        />
        <div className="col-lg-14">
          <div id="d3graph" style={{paddingLeft:"0px"}}>
            <div className="card" style={{margin:"0px"}}>
              <div className="card-body" style={{}}>
                {/* <div class="card-widgets">
                                   
                                    <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                                        data-toggle="fullscreen" href="/#">
                                        <i class="fe-maximize noti-icon"></i></a>
                                </div> */}
                <div className="row">
                  <span class="header-title" style={{ color: "#fff" }}>
                    <div class="btn-group">
                      <button
                        className={`btn btn-primary ${
                          this.state.highlighted === 1 ? "highlighted" : ""
                        }`}
                        onClick={(t) => this.switch_rasiview(t)}
                      >
                        {t("Rashi View")}
                      </button>
                      <button
                        className={`btn btn-primary ${
                          this.state.highlighted === 2 ? "highlighted" : ""
                        }`}
                        onClick={(t) => this.switch_naksview(t)}
                      >
                        {t("Nakshatra View")}
                      </button>
                      <button
                        className={`btn btn-primary ${
                          this.state.highlighted === 3 ? "highlighted" : ""
                        }`}
                        onClick={(t) => this.switch_combinedview(t)}
                      >
                        {" "}
                        {t("Combined View")}{" "}
                      </button>
                    </div>
                  </span>
                  {/* <div class="row">
                    <div class="col-lg-12">
                      <form class="form-inline" style={{ fontSize: "1em" }}>
                        <div class="form-group mx-md-3">
                          <label class="mr-2">{t("Location")}:</label>
                          <label class="mr-2" style={{ color: "#343a40" }}>
                            {this.context.requestParams.name}
                          </label>
                        </div>
                        <div class="mx-md-3">
                          <span class="mr-2">{t("Start Date")}:</span>
                          <label class="mr-2">{this.context.startDate}</label>
                        </div>
                        <div class="mx-md-3">
                          <span class="mr-2">{t("End Date")}:</span>
                          <label class="mr-2">{this.context.endDate}</label>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => this.resetForm()}
                          style={{
                            marginLeft: "40px",
                          }}
                        >
                          <i className="mdi mdi-refresh"></i>
                          {t("Reset")}
                        </button>
                      </form>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <center>
                      <div id="myDiv_NAKS"></div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Plotly_NAKS));


