import React, { Component } from "react";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import CollapseTable from "./CollapseTable";
import ShadBalaBarGraph from "./ShadBalaBarGraph";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';

function pad(n) {
  return n < 10 ? "0" + n : n;
}

function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

const date = new Date();
let time =
  pad(date.getHours()) +
  ":" +
  pad(date.getMinutes()) +
  ":" +
  pad(date.getSeconds());

class ShadBala extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      tabledata: [],
      calendarDate: date,
      newTime: time,
      renderChoice: "table",
      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.CreateTableFormattedJson = this.CreateTableFormattedJson.bind(this);
    this.timeChange = this.timeChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
    let horoscopeData;
    let queryString = getQueryParam('cb');
    if(queryString){
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`)
      );
    }
    else{
      horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData")
      );
    }
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (requestParams !== null &&Object.keys(this.context.requestParams).length === 0) {
      this.context.setRequestParamsHoroscope(requestParams);
    }
    if (horoscopeData !== null &&Object.keys(this.context.horoscopeData).length === 0) {
      this.context.setHoroscopeData(horoscopeData);
    }
    if (horoscopeData === null) {this.context.handleHoroscopeForm(true, "open");} 
    else {
      this.CreateTableFormattedJson(horoscopeData);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      // console.log(this.props.horoscopeData, prevProps.horoscopeData);
      this.CreateTableFormattedJson(this.props.horoscopeData);
    }
  }

  CreateTableFormattedJson(horoscopeData) {
    var outerjson = [];
    let data = horoscopeData.graha_bala;
    // console.log(horoscopeData);
    let primaryKeys = Object.keys(data);
    for (let i in primaryKeys) {
      let json = {};
      let iterable = data[primaryKeys[i]];
      if (iterable instanceof Array) {
        json["Column1"] = humanize(primaryKeys[i]);
        json["Column2"] = "";
        for (let j in iterable) {
          let details = iterable[j];
          json[details.planet_name] = details.unit_value;
        }
        outerjson.push(json);
      } else {
        json = {
          Column1: humanize(primaryKeys[i]),
          Column2: "",
          Sun: "",
          Moon: "",
          Mars: "",
          Mercury: "",
          Jupiter: "",
          Venus: "",
          Saturn: "",
        };
        let primaryKeysInner = Object.keys(iterable);
        outerjson.push(json);
        for (let k in primaryKeysInner) {
          let childjson = {};
          if (iterable[primaryKeysInner[i]] instanceof Array) {
            childjson["Column1"] = "";
            childjson["Column2"] = humanize(primaryKeysInner[k]);
            for (let l in iterable[primaryKeysInner[k]]) {
              let details = iterable[primaryKeysInner[k]][l];
              childjson[details.planet_name] = details.unit_value;
            }
            outerjson.push(childjson);
          }
        }
      }
    }

    this.setState({ tabledata: outerjson });
  }

  dateChange = (date) => {
    this.setState({ calendarDate: date });
  };
  timeChange = (time) => {
    this.setState({ newTime: time.target.value });
  };

  renderElement = (value) => {
    this.setState({ renderChoice: value });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Sideform
          show={this.context.horoscopeForm}
          mode={"Horoscope"}
          view={"shadBala"}
          handleClose={this.context.handleHoroscopeForm}
          reset={false}
          history={this.props.history}
        />
        <div className="col-lg-14">
          <div id="snapshot">
            <div className="card">
              <div className="card-body">
                <center className="section-header">
                  <span className="title mr-2">t{"Shad Bala"}</span>
                  <label style={{ fontSize: "20px", fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="optradio"
                      value="table"
                      checked={this.state.renderChoice === "table"}
                      onClick={() => this.renderElement("table")}
                    />
                    {t("Table")}
                  </label>
                  <label style={{ fontSize: "20px", fontWeight: "normal" }}>
                    <input
                      type="radio"
                      name="optradio"
                      value="chart"
                      style={{ marginLeft: "20px" }}
                      onClick={() => this.renderElement("chart")}
                      checked={this.state.renderChoice === "chart"}
                    />
                    {t("Chart")}
                  </label>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => this.context.handleHoroscopeForm(true)}
                    style={{
                      float: "right",
                      marginRight: "30px",
                    }}
                  >
                    <i className="mdi mdi-refresh"></i>
                    {t("Reset")}
                  </button>
                  {this.state.renderChoice === "table" ? (
                    <CollapseTable data={this.state.tabledata} />
                  ) : (
                    <ShadBalaBarGraph />
                  )}
                </center>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ShadBala;
