import React, { useState } from "react";
import "react-bootstrap";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import { withRouter } from "react-router-dom";
import "../InitDashboard.css";
import galactic_icon from "../../../assets/svg/galactic.svg";
import traces_icon from "../../../assets/svg/traces.svg";
import journey_icon from "../../../assets/svg/journey.svg";
import snapshot_icon from "../../../assets/svg/snapshot.svg";
import kaal_icon from "../../../assets/svg/kaal_purush.svg";
import sun_moon from "../../../assets/svg/sunmoon.svg";
import conjunction from "../../../assets/svg/conjunction.svg"
// src/assets/svg/conjunction.svg
import { color } from "d3-color";
import { useTranslation } from "react-i18next";

const InitDashboard = (props) => {
  const context = React.useContext(GlobalContext);
  let [localview, setLocalview] = useState(context.currentView);
  let [localmode, setLocalmode] = useState("TransitionView");
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setShow(!show);
  };

  const AreSnapshotDetailsFilled = () => {
    if (
      Object.keys(context.requestParams).length === 0 &&
      context.requestParams.constructor === Object
    ) {
      return false;
    } else {
      return true;
    }
  };

  // const handlePlainView = (selectedName) => {
  //   context.change_View(selectedName);
  // };

  const AreDetailsFilled = () => {
    if (
      (Object.keys(context.requestParams).length === 0 &&
        context.requestParams.constructor === Object) ||
      context.startDate === null ||
      context.endDate === null
    ) {
      return false;
    } else {
      return true;
    }
  };
  const AreConjunctionDetailsFilled = () => {
    if (
      (Object.keys(context.requestParams).length === 0 &&
        context.requestParams.constructor === Object) ||
      context.planetConjunctionStart === null ||
      context.planetConjunctionEnd === null || 
      context.selectedConjunctionArray.length<=1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const AreLongJourneyDetailsFilled = () => {
    if (
      (Object.keys(context.requestParams).length === 0 &&
        context.requestParams.constructor === Object) ||
      context.longTermJourneyStartDate === null ||
      context.longTermJourneyStartDate === null || 
      context.longTermJourneyPlanet === null
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleView = (selectedName) => {
    console.log(selectedName);
    if (
      selectedName === "snapshotView" ||
      selectedName === "kaalPurushView" ||
      selectedName === "RankBars" ||
      selectedName === "ShadBalaTable" ||
      selectedName === "BhavBala"
    ) {
      setLocalmode("Snapshot");
      setLocalview(selectedName);
      if (AreSnapshotDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        setShow(!show);
      }
    }else if(selectedName==="planetaryConjunctions"){
      setLocalmode("PlanetaryConjunctionMode");
      setLocalview(selectedName);
      if (AreConjunctionDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        setShow(!show);
      }
    }else if(selectedName==="longtermjourney"){
      setLocalmode("TransitionView");
      setLocalview(selectedName);
      if (AreLongJourneyDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        setShow(!show);
      }
    } else {
      setLocalmode("TransitionView");
      setLocalview(selectedName);
      if (AreDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        setShow(!show);
      }
    }
  };
  return (
    <>
      <Sideform
        show={show}
        mode={localmode}
        handleClose={handleClose}
        view={localview}
        reset={false}
        history={props.history}
      />

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-galactic">
            <div className="card-body ">
              <h4 className="header-title title-color mb-0">
                {t("Galactic View")}
              </h4>

              <div id="cardCollpase_galactic" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {t(
                        "Explore how the planets move in zodiac orbit with respect to each other with direct and retro motion highlighting their entry and exit in all the Nakshtras over a period of time."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("galacticView")}
                    >
                      {" "}
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="galactic view"
                      className="introimage mb-auto"
                      src={galactic_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-traces">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase2"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title title-color mb-0">
                {t("Traces View")}
              </h4>

              <div id="cardCollpase2" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {t(
                        "Get insight into the traces of the path of planets as they move through the zodiac signs. It is a very interesting perspective showing pattern of their movements with respect to each other."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("tracesView")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Traces view"
                      className="introimage mb-auto"
                      src={traces_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title title-color mb-0">
                {t("Planet Journey")}
              </h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {" "}
                      {t(
                        "A Planet meets several milestones like Rashi change, Nakshtra change, Retro and combustion throughout its journey. See how these milestones are mapped against the timeline in a planet’s journey."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("journeyView")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              <h4 className="header-title title-color mb-0">
                {t("Long Term Planet Journey")}
              </h4>
              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {" "}
                      {t(
                        "A Planet meets several milestones like throughout its journey over the course of years. See milestones mapped against the timeline in a planet’s journey and take a dive into the traces of the path of a planet as it moves through the zodiac signs."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("longtermjourney")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-snapshot">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase3"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title title-color mb-0">
                {" "}
                {t("Snapshot Now")}
              </h4>

              <div id="cardCollpase3" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {" "}
                      {t(
                        "This view gives detailed planets positions in the sky at the current time. You are free to change the time and get a snapshot of that time."
                      )}
                    </p>
                    <br></br> <br></br>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("snapshotView")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="snapshot"
                      className="introimage mb-auto"
                      src={snapshot_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-kaal">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase2"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title title-color mb-0">
                {" "}
                {t("Transit for Kaal Purush")}
              </h4>

              <div id="cardCollpase2" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {t(
                        "This shows the placements of planets in Vedic kundali with Aries as ascendant for the current date and time. But you can play with date, time, and Ascendant and see how it planets change their place in Kundali."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("kaalPurushView")}
                    >
                      {" "}
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="kaal purush"
                      className="introimage mb-auto"
                      src={kaal_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-sunmoon">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title title-color mb-0">
                {" "}
                {t("Sun and Moon Phases")}
              </h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {t(
                        "It is very interesting to see the Sun’s journey through different rashis with respect to moon phases. It gives a clear picture of “Kshay”(Less) and “Adhik”(Additional) month graphically on timeline."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("sunAndMoonView")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="sun moon"
                      className="introimage mb-auto"
                      src={sun_moon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-conjunction">
            <div className="card-body">
              <h4 className="header-title title-color mb-0">
                {" "}
                {t("Planetary Conjunction")}
              </h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      {t(
                        "A graph illustrating planetary conjunctions over specific year range and particular rashis (zodiac signs).  This visualization depicts when and where planetary conjunctions occur in the given timeframe and zodiac signs."
                      )}
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => handleView("planetaryConjunctions")}
                    >
                      {t("Explore Here")}
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                    width="20%"
                    height="20%"
                      alt="Planetary Conjunctions"
                      className="introimage mb-auto"
                      src={traces_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default withRouter(InitDashboard);
