import React, { useEffect, useState } from "react";
import { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import axios from "axios";
import { Select } from "@material-ui/core";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import * as cities from "../../cities.json";
import Autocomplete from "../../SideComponents/Autocomplete/autocomplete";
import { GlobalContext } from "../../../mycontext";
import "../../SideComponents/sideform.css";
import Modal from "react-bootstrap/Modal";
import Api from "../../../API/Api";

import CountryAutocomplete from "../../SideComponents/Autocomplete/CountryAutocomplete";
import { withTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { IMaskInput } from "react-imask";
import DatePicker from "react-date-picker";

class LongTermJourneySideform extends Component{

  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
        longTermJourneyStartDate: null,
        longTermJourneyEndDate: null,
      city: "",
      focusedEndDate: false,
      isValidDateRange: false,
      errors: {},
      open: "",
      resetInputText: false,
      userInput: null,
    planetName:window.localStorage.getItem("longTermJourneyPlanet"),
      language:window.localStorage.getItem("UserDefaultLanguage")
        };
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Api = new Api();
  }


  componentDidMount() {
    let start = window.localStorage.getItem("longTermJourneyStartDate");
    if(start!==null){
        this.setStartDate(moment(new Date(start)));
    }
    let end = window.localStorage.getItem("longTermJourneyEndDate");
    if(end!==null){
        this.setEndDate(moment(new Date(end)));
    }
   }

  componentDidUpdate(){
    if(window.localStorage.getItem("UserDefaultLanguage")!==this.state.language){
      this.setState({language:window.localStorage.getItem("UserDefaultLanguage")})
  }
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
    this.context.changeView(event.target.value);
  }


  alertclick = async (e) => {
    e.preventDefault();
    let start_Date = "";
    let end_Date = "";
    let from_year = "",
      from_month = "",
      from_day = "";
    let to_year = "",
      to_month = "",
      to_day = "";
    let lat = "",
      long = "";
    let offset = "";
    this.context.setCountry(this.context.requestParams.country, "Transit");
        this.context.setCityAndState(
          `${this.context.requestParams.name},${this.context.requestParams.state}`,
          "Transit"
        );
    
    start_Date = this.state.longTermJourneyStartDate || this.context.longTermJourneyStartDate;
    end_Date = this.state.longTermJourneyEndDate || this.context.longTermJourneyEndDate;
    let country = this.context.requestParams.country;
    let state = `${this.context.requestParams.state}`;
    // console.log(this.context.transitCityState,'<-',document.getElementById("auto_complete1").value,'<------')
    //  let names = document.getElementById("auto_complete1").value;
    let names = `${this.context.requestParams.name},${this.context.requestParams.state}`;
    this.setState({ city: names });
    from_year = moment(start_Date).format("YYYY");
    from_month = moment(start_Date).format("MM");
    from_day = moment(start_Date).format("DD");
    to_year = moment(end_Date).format("YYYY");
    to_month = moment(end_Date).format("MM");
    to_day = moment(end_Date).format("DD");
    let errors = this.state.errors;
    let isFormValid = true;
    // console.log(this.state.userInput);
    // console.log(country, state);
    let planetMap = {Jupiter:5,
        Saturn:6,
        Rahu:10,
        Ketu:100};
    let planetName = this.state.planetName;
    if (country === "") {
      errors["error"] = "Please enter valid location";
      isFormValid = false;
      return;
    } else if (state === "") {
      errors["error"] = "Please enter valid location";
      isFormValid = false;
      return;
    } else if (start_Date == null || end_Date == null) {
      errors["error"] = this.props.t("Please select date range.");
      isFormValid = false;
      return;
    }else if (planetName == null) {
        errors["error"] = this.props.t("Please select Planet.");
        isFormValid = false;
        return;
    } else if(!this.isDateRangeValid(start_Date,end_Date)){
        isFormValid=false;
        return;
    }else {
      this.context.resetLoading(true);
      if (Object.keys(this.context.requestParams).length === 0) {
        errors["error"] = "Please select valid place of observation";
        this.context.resetLoading(false);
        isFormValid = false;
      } else {
        lat = this.context.requestParams.latitude;
        long = this.context.requestParams.longitude;
        offset = this.context.requestParams.offset;
        this.setState({ errors: errors });
        let params =
          "?from_year=" +
          from_year +
          "&from_month=" +
          from_month +
          "&from_day=" +
          from_day +
          "&to_year=" +
          to_year +
          "&to_month=" +
          to_month +
          "&to_day=" +
          to_day +
            "&lat=" +
          lat +
          "&long=" +
          long +
          "&offset=" +
          offset +
          "&city=" +
          this.context.requestParams.name +
          "&state=" +
          this.context.requestParams.state +
          "&country=" +
          this.context.requestParams.country +
          "&reflong=" +
          this.context.requestParams.reference_longitude_for_timezone +
          "&timezone=" +
          this.context.requestParams.timezone +
          "&token=" +
          localStorage.getItem("omp_token");

        let api_result=await this.Api.call_APIs_for_long_term_planet_journey(params,planetMap[planetName]);
        
        if(Object.keys(api_result?.traces_view_data).length!==0 && Object.keys(api_result?.journey_view_data).length!==0){
            this.context.setLongTermJourneyAPIData(api_result);
                this.context.changeSideTableDisplay(false);
                this.props.handleClose();
                this.props.history.push(`/dashboard/${this.props.view}`);
                this.context.resetLoading(false);
        }

        
      }
      this.context.resetForm();
    }
  };

  getdataLongTermPlanetJourney=async(e)=>{
    e.preventDefault();

  }

  callClose = () => {
    this.props.close();
  };

setPlanetName=(planet)=>{
    this.context.setLongTermJourneyPlanet(planet);
    this.setState({planetName:planet});
}
  setStartDate=(date)=>{
    this.setState({ longTermJourneyStartDate: date });
    this.context.setlongTermJourneyStartorEndDate("Start",date)
  }
  setEndDate=(date)=>{
    this.setState({ longTermJourneyEndDate: date });
    this.context.setlongTermJourneyStartorEndDate("End",date)
  }

  isDateRangeValid =(start,end)=>{
    let errors = this.state.errors;
    let isValidDateRange = true;
    this.setState({ errors });
      if (start!==null && end !== null) {
        let isBefore = moment(start).isBefore(
         end
        );
        if (!isBefore) {
          errors["dateError"] = "End date should be greater than start date";
          isValidDateRange = false;
        } else if (start.diff(end, "year") > 40) {
          errors["dateError"] =
            "Please select date range within 40 years";
          isValidDateRange = false;
        }
      } else {
        errors["dateError"] = "Please select start and end dates";
        isValidDateRange = false;
        this.setState({ disableButton: true });
        isValidDateRange = false;
      }
    this.setState({ errors: errors });
    this.setState({ isValidDateRange: isValidDateRange });
    return isValidDateRange;
  }
  // set all variable values
  onDateChange = (name, date) => {
    let errors = this.state.errors;
    let isValidDateRange = true;
    errors["dateError"] = "";
    this.setState({ errors });
    if (name === "startDate") {
    //   this.setState({ longTermJourneyStartDate: date });
    this.setStartDate(date);
      if (this.state.longTermJourneyEndDate|| this.context.longTermJourneyEndDate) {
        let isBefore = moment(date).isBefore(
          this.state.longTermJourneyEndDate || this.context.longTermJourneyEndDate
        );
        if (!isBefore) {
          errors["dateError"] = "End date should be greater than start date";
          isValidDateRange = false;
        } else if (date.diff(this.state.longTermJourneyEndDate, "year") > 40) {
          errors["dateError"] =
            "Please select date range within 40 years";
          isValidDateRange = false;
        }
      } else {
        isValidDateRange = false;
      }
    } else if (name === "endDate") {
    //   this.setState({ longTermJourneyEndDate: date });
    this.setEndDate(date);
      if (this.state.longTermJourneyStartDate || this.context.longTermJourneyStartDate) {
        let isAfter = moment(date).isAfter(
          this.state.longTermJourneyStartDate || this.context.longTermJourneyStartDate
        );
        if (!isAfter) {
          errors["dateError"] = "End date should be greater than start date";
          isValidDateRange = false;
        } else if (date.diff(this.state.longTermJourneyStartDate, "year") > 40) {
          errors["dateError"] =
            "Please select date range within 40 year";
          isValidDateRange = false;
        }
      } else {
        errors["dateError"] = "Please select start date";
        isValidDateRange = false;
        this.setState({ disableButton: true });
      }
    } 
    this.setState({ errors: errors });
    this.setState({ isValidDateRange: isValidDateRange });
  };

  handleAutoCompleterChange = (type) => {
    if (type === "userInput") {
      let errors = this.state.errors;
      errors["observation"] = "";
      this.setState({ errors: errors });
    } else if (type === "reset") {
      this.setState({ resetInputText: false });
    }
  };

  resetForm() {
    // this.context.dataLoaded = false;
    // this.context.apidataState = {};
    this.setState({ open: "" });
    this.setState({ longTermJourneyStartDate: null });
    this.setState({ longTermJourneyEndDate: null });
    this.setState({ city: "" });
  }

  handleFocus = (focused) => {
    this.setState({ focused });
  };

  handlePlaceDetailsChange = (place) => {
    this.setState({ placeDetails: place });
  };

  handleUserInput = (input) => {
    this.setState({ userInput: input });
  };

  render() {
    const { t, i18n } = this.props;
    //console.log(cities.default);
    var city = cities.default[0];
    var cities_name = Object.keys(city);
    //console.log(cities_name);
    const falseFunc = () => false;
    const min = "1900-01-01";
    const max = "2399-12-30";
    const returnYears = () => {
      let years = [];
      for (
        let i = parseInt(moment(min).format("YYYY"));
        i <= parseInt(moment(max).format("YYYY"));
        i++
      ) {
        years.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return years;
    };
    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <select
              value={
                moment(month).isBefore(moment(min))
                  ? moment(min).month()
                  : month.month()
              }
              onChange={(e) => {
                if (month.isBefore(moment(min))) {
                  onMonthSelect(moment(min), moment(min).month());
                } else {
                  onMonthSelect(month, e.target.value);
                }
              }}
            >
              {moment.months().map((label, value) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={month.year()}
              onChange={(e) => {
                onYearSelect(month, e.target.value);
              }}
            >
              {returnYears()}
            </select>
          </div>
        </div>
      );
    };


    //  if(this.props.flag==="datesideform"){
    //         this.setState({displaypanchangDate:null})
    // }
    // if(this.props.flag==="placesideform"){
    //         this.setState({displaypanchangDate:this.state.panchangDate})
    // }
    const mode = this.props.mode;
      return (
        <>
          <div className="">
            <Modal
              animation={false}
              size="md"
              dialogClassName={"primaryModal"}
              show={this.props.show}
              onHide={this.props.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("Update Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{}}>
                <div className="card">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("Country of Observation")}

                        </label>
                        <CountryAutocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}

                          city={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("City of Observation")}
                        </label>
                        <Autocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          country={this.context.transitCountry}
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="example-input-small"
                          style={{ marginBottom: "0px" }}
                        >
                          {t("Select Date Range")}
                        </label>
                      </div>
                      <label htmlFor="example-input-small">{t("From")}</label>
                      <div className="mb-1">
                        <SingleDatePicker
                          numberOfMonths={1}
                          date={
                            this.state.longTermJourneyStartDate ||
                            (this.context.longTermJourneyStartDate !== null
                              ? moment(this.context.longTermJourneyStartDate)
                              : null)
                          } // momentPropTypes.momentObj or null
                          onDateChange={(date) =>
                            this.onDateChange("startDate", date)
                          } // PropTypes.func.isRequired
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) =>
                            this.setState({ focused })
                          } // PropTypes.func.isRequired
                          id="start_date" // PropTypes.string.isRequired,
                          placeholder={t("Start Date")}
                          isOutsideRange={falseFunc}
                          displayFormat={() => "DD-MM-YYYY"}
                          // minDate={moment(min)}
                          // maxDate={moment(max)}
                          renderMonthElement={renderMonthElement}
                          readOnly={true}
                        />
                      </div>
                      <label htmlFor="example-input-small">{t("to")}</label>
                      <div className="">
                        <SingleDatePicker
                          numberOfMonths={1}
                          date={
                            this.state.longTermJourneyEndDate ||
                            (this.context.longTermJourneyEndDate !== null
                              ? moment(this.context.longTermJourneyEndDate)
                              : null)
                          } // momentPropTypes.momentObj or null
                          onDateChange={(date) =>
                            this.onDateChange("endDate", date)
                          } // PropTypes.func.isRequired
                          focused={this.state.focusedEndDate} // PropTypes.bool
                          onFocusChange={({ focused: focusedEndDate }) =>
                            this.setState({ focusedEndDate })
                          } // PropTypes.func.isRequired
                          id="end_date" // PropTypes.string.isRequired,
                          placeholder={t("End Date")}
                          isOutsideRange={falseFunc}
                          displayFormat={() => "DD-MM-YYYY"}
                          // minDate={moment(min)}
                          // maxDate={moment(max)}
                          renderMonthElement={renderMonthElement}
                          readOnly={true}
                        />
                        <div>
                        <p className="sub-header" style={{color:"red"}}>
                          {t(this.state.errors.dateError)}
                        </p>
                      </div>
                      </div>
                      <div>
                        <p className="sub-header">
                          {t("Maximum Date Range allowed is 40 years")}
                        </p>
                      </div>
                      <div className="" style={{"padding-top":"10px"}}>
                        <label htmlFor="example-input-small">{t("Planets")}</label>
                        <br></br>
                        <select className="planetSelector" value={this.state.planetName||this.context.longTermJourneyPlanet} onChange={(e)=>this.setPlanetName(e.target.value)}>
                            <option selected={true} value={null} key={0} disabled>{t("Select Planet")} </option>
                        {["Jupiter","Saturn","Rahu","Ketu"].map((i,index)=>{
                            return(<option style={{padding:"2px"}} value={i} key={index+1} //selected={this.context.setLongTermJourneyAPIData===i}
                            >{t(i)}</option>)
                        })}
                        </select>
                        
</div>
                      <div>
                        {this.state.errors.error && (
                          <p className="form-error">
                            {" "}
                            {this.state.errors.error}
                          </p>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="ladda-button btn"
                        style={{
                          backgroundColor: "#03428D",
                          color: "#fff",
                          marginTop: "5%",
                        }}
                        onClick={this.alertclick}
                        disabled={this.context.IsLoading}
                      >
                        {this.context.IsLoading ? (
                          <span>
                            {t("Getting Data")}{" "}
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (
                          t("Get Data")
                        )}
                      </button>
                      {/* </>)} */}
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          {/* New div for showing table data in this component only  */}
        </>
      );
    
}
}
export default withTranslation()(withRouter(LongTermJourneySideform));