import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ChakraTable from "./ChakraTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { GlobalContext } from "../../../mycontext";
import "./Chakra.css";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import { withTranslation } from "react-i18next";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootCard: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5%",
  },
  CardContent: {},
  PlanetaryRelationDiv: {
    minHeight: "650px",
    position: "relative",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  searchDiv: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "5%",
  },
  resultdiv: {
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: "300px",
  },
  tab: {
    color: "grey",
  },
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ValueMapper = {
  1: "basic_details",
  2: "avkhada_chakra",
  3: "ghatak_chakra",
};

const HeaderMapper = {
  1: "General Details",
  2: "Avkhada Chakra",
  3: "Ghatak Chakra",
};

class Chakra extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {tableData: [],value: 1,showTable: true,relation: "basic_details",cb_view:false,cb_horoscopedata:''};
    this.FilterData = this.FilterData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // Check if query parameters are present in the URL
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cbParam = urlParams.get('cb');
  console.log("querystring -->", cbParam);

  let googleAuthObj;
  let requestParams;
  let horoscopeData;
  
  if (cbParam) {
    googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );

    requestParams = JSON.parse(
      window.sessionStorage.getItem(`requestParamsHoroscope_${cbParam}`)
    );

    horoscopeData = JSON.parse(
      window.sessionStorage.getItem(`horoscopeData_${cbParam}`)
    );
    this.setState({ cb_view: true });
    this.setState({cb_horoscopedata:horoscopeData});
    this.handleChange(1);
  }
  else{
    googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }

    requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (requestParams !== null && Object.keys(this.context.requestParamsHoroscope).length === 0) 
    {
      this.context.setRequestParamsHoroscope(requestParams);
    }

    horoscopeData = JSON.parse(
      window.localStorage.getItem("horoscopeData")
    );
    if (horoscopeData !== null && Object.keys(this.context.horoscopeData).length === 0) 
    {
      this.context.setHoroscopeData(horoscopeData);
    }
    if (horoscopeData === null) {
      console.log("----inside 122");
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.FilterData(this.state.relation, horoscopeData);
    }
  }
  
 
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.FilterData(this.state.relation, this.context.horoscopeData);
    }
  }

  FilterData(param, horoscopeData) {
    let data = horoscopeData;
    let requiredData = data[param];
    let mainjson = [];
    for (let i in requiredData) {
      let json = {};
      json["Column1"] = capitalizeFirstLetter(i);
      json["Column2"] = requiredData[i];
      mainjson.push(json);
    }
    this.setState((state) => {
      return {
        tableData: mainjson,
      };
    });
  }

  handleChange = (newValue) => {
    if (newValue === "4" ) {
      console.log("----inside--157-");
      this.context.handleHoroscopeForm(true);
    } else {
      this.setState({ value: newValue, relation: ValueMapper[newValue] }, () =>
      (this.state.cb_view)? this.FilterData(ValueMapper[newValue], this.state.cb_horoscopedata) : 
        this.FilterData(ValueMapper[newValue], this.context.horoscopeData)
      );
    }
  };

  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;
    const { tableData } = this.state;
    if ( this.state.cb_view || (Object.keys(this.context.horoscopeData).length > 0 && typeof this.context.horoscopeData !== "undefined")) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"basicdetails"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div
            className={classes.PlanetaryRelationDiv}
            style={{
              borderRadius: "10px",
              background: "#fff",
            }}
          >
            <ResetButton />

            <div className="panel with-nav-tabs panel-warning">
              <div className="panel-heading">
                <div className="row">
                  <Tabs
                    id="controlled-tab-example"
                    className="d-flex"
                    activeKey={this.state.value}
                    onSelect={(k) => this.handleChange(k)}
                  >
                    <Tab eventKey={1} title={t("General Details")}></Tab>
                    <Tab eventKey={2} title={t("Avkhada Chakra")}></Tab>
                    <Tab eventKey={3} title={t("Ghatak Chakra")}></Tab>
                  </Tabs>
                  {/* <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => this.context.handleHoroscopeForm(true)}
                style={{
                  marginRight: "0px",
                }}
              >
                <i className="mdi mdi-refresh"></i> Reset
              </button> */}
                </div>
              </div>
              {(Object.keys(this.context.horoscopeData).length > 0 || this.state.cb_view ) && (
                <ChakraTable
                  data={tableData}
                  heading={HeaderMapper[this.state.value]}
                  rashi={(this.state.cb_view)?this.state.cb_horoscopedata.avkhada_chakra.rashi
                    :this.context.horoscopeData.avkhada_chakra.rashi}
                  nakshatra={(this.state.cb_view)? this.state.cb_horoscopedata.avkhada_chakra["Nakshtra pada"].split("-")[0] : this.context.horoscopeData.avkhada_chakra["Nakshtra pada"].split("-")[0]}
                  asc={(this.state.cb_view)? this.state.cb_horoscopedata.avkhada_chakra.lagana
                    : this.context.horoscopeData.avkhada_chakra.lagana}
                  horoscopeData={(this.state.cb_view)? this.state.cb_horoscopedata : this.context.horoscopeData}
                />
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"basicdetails"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
        </>
      );
    }
  }
}
export default withTranslation()(
  withStyles(styles, { withTheme: true })(Chakra)
);
