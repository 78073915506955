import React, { useContext, useEffect, useState, Fragment } from "react";
import "react-bootstrap";
import "./DashboardTopBar.css";
import { Link, withRouter } from "react-router-dom";
import { changeLanguageAPI } from "../../API/UserAPIs";
import { GlobalContext } from "../../mycontext";
import loadjs from "loadjs";
import Logout from "../GoogleLogin/logout";
import "../../i18n";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Api from "../../API/Api";
import { downloadReportPDF } from "../../API/UserAPIs";
import moment from "moment";
import {getQueryParam} from '../utils/utility';

const languageOptions = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];

const genderMapper = {
  Male: 0,
  M: 0,
  पुरुष: 0,
  Female: 1,
  F: 1,
  स्त्री: 1,
  "Male to Female": 2,
  MF: 2,
  "पुरुष से महिला": 2,
  "Female to Male": 3,
  FM: 3,
  "महिला से पुरुष": 3,
  Others: 4,
  अन्य: 4,
  "Not Applicable": 5,
  "N/A": 5,
  "उपयुक्त नहीं": 5
};

const reportOptions = [
  { value: "Short Report", label: "Short Report" },
  { value: "Detailed Report", label: "Detailed Report" },
];

function timeFormatter(name, time) {
  var ampm = time.slice(time.length - 2, time.length);
  var SplitTime = time.slice(0, time.length - 2).split(":");
  var h = parseInt(SplitTime[0]);
  var min = parseInt(SplitTime[1]);
  var sec = parseInt(SplitTime[2].trim());
  if (ampm === "AM" && h === 12) {
    h = 0;
  } else if (ampm === "PM" && h === 12) {
    h = 12;
  } else if (ampm === "PM") {
    h = 12 + h;
  }

  if (name === "hour") {
    return h;
  }
  if (name === "min") {
    return min;
  }
  if (name === "sec") {
    return sec;
  }
}

var hourArray = Array.from({ length: 24 }, (_, i) => i + 1);
hourArray.pop();
hourArray.unshift(0);
var minSecArray = Array.from({ length: 60 }, (_, i) => i + 1);
minSecArray.pop();
minSecArray.unshift(0);

var yearArray = [];
for (var i = 1900; i <= 2200; i++) {
  yearArray.push(i);
}

const processDates = (date_string) => {
  let parts_of_date = date_string.split("-");

  let output = new Date(
    +parts_of_date[2],
    parts_of_date[1] - 1,
    +parts_of_date[0]
  );
  return output;
};

const DashboardTopBarCelebrity = (props) => {
  const { t, i18n } = useTranslation();
  const context = useContext(GlobalContext);
  const languageDict = {
    value: localStorage.getItem("UserDefaultLanguage"),
    label: localStorage.getItem("UserDefaultLanguage"),
  };

  const [isActive, setActive] = useState(false);
  const [isToggle, setToggle] = useState(false);
  const [openLangModal, setOpenLangModal] = useState(false);
  const [openReportsModal, setOpenReportsModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageDict);
  let [loading, setLoading] = useState(false);
  let [yogaLoading, setYogaLoading] = useState(false);
  const [celebName, setCelebName] = useState('');
  let [horoscopeData, setHoroscopeData] = useState(null);
  let [requestParamsHoroscope, setRequestParamsHoroscope] = useState(null);
  const [selectedReport, setSelectedReport] = useState({
    value: "Short Report",
    label: "Short Report",
  });

  useEffect(() => {
    let queryString = getQueryParam('cb');
    let sessionData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
    console.log("query--->",sessionData.native_name);
    setHoroscopeData(sessionData);
    setCelebName(sessionData.native_name);
    if(queryString){
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
      setRequestParamsHoroscope(JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`)));
    }
    else{
    horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    setRequestParamsHoroscope(JSON.parse(window.localStorage.getItem(`requestParamsHoroscope`)));
  }

    let language = localStorage.getItem("UserDefaultLanguage");
    if (language !== null) {
      if (language === "Hindi") {
        i18n.changeLanguage("hi");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  let api = new Api();

  let downloadHoroscopeReport = () => {
    setYogaLoading(true);
    try {
      let lan =
        localStorage.getItem("UserDefaultLanguage") === "English"
          ? "en"
          : "hi";

      api.callReportAPI(lan).then((res1) => {
        if (typeof res1 !== "undefined") {
          if (res1 === "API Issue") {
            // console.log("Issue with Yoga Reports API");
          } else {
            // console.log("Calling DownloadReportUser API");
            downloadReportPDF(res1.data.url).then((res2) => {
              if (typeof res2 !== "undefined") {
                // console.log(res2);
                const fileName =
                  "Omparashar-Horoscope-" + "Yoga-Report" + ".pdf";
                const blobobject = new Blob([res2.data], {
                  type: "application/pdf",
                });
                // console.log(blobobject);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blobobject);
                link.setAttribute("download", fileName);
                link.click();
                setYogaLoading(false);
                // link.download = "FileName" + new Date() + ".pdf";
                // link.click();
              } else {
                // console.log("issue with Download Report EndPoint in User APIs");

                setYogaLoading(false);
              }
            });
          }
        } else {
          // console.log(
          // "issue with Download Report EndPoint in Image Service APIs"
          //);
          setLoading(false);
        }
      });
    } catch (err) {
      // console.log("Issue with Reports API");
      // console.log(err);
    }
  };

  let callReportAPIHandler = () => {
    alert('hi')
    setOpenReportsModal(false);
    setLoading(true);
    try {
      // console.log("sessionData---",horoscopeData.native_name);
      // console.log("context----",context.horoscopeData.native_name)
      let name = horoscopeData.native_name;
      let gender = horoscopeData.basic_details.gender;
      let year = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getFullYear()
        : null;

      let month = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getMonth() + 1
        : null;

      month = parseInt(month);
      let day = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getDate()
        : null;

      let hour = horoscopeData.time_of_birth
        ? timeFormatter("hour", horoscopeData.time_of_birth)
        : "";

      let min = horoscopeData.time_of_birth
        ? timeFormatter("min", horoscopeData.time_of_birth)
        : "";

      let sec = horoscopeData.time_of_birth
        ? timeFormatter("sec", horoscopeData.time_of_birth)
        : "";

      api
        .callReportAPI(
          name,
          gender,
          day,
          month,
          year,
          hour,
          min,
          sec,
          requestParamsHoroscope.latitude,
          requestParamsHoroscope.longitude,
          requestParamsHoroscope.offset,
          requestParamsHoroscope.name,
          requestParamsHoroscope.state,
          requestParamsHoroscope.country,
          requestParamsHoroscope.reference_longitude_for_timezone,
          requestParamsHoroscope.timezone,
          localStorage.getItem("omp_token"),
          selectedReport.value
        )
        .then((res1) => {
          if (typeof res1 !== "undefined") {
            if (res1 === "API Issue") {
              // console.log("Issue with Reports API");
            } else {
              // console.log("Calling DownloadReportUser API");
              downloadReportPDF(res1.data.url).then((res2) => {
                if (typeof res2 !== "undefined") {
                  // console.log(res2);
                  const fileName = "Omparashar-Horoscope-" + name + ".pdf";
                  const blobobject = new Blob([res2.data], {
                    type: "application/pdf",
                  });
                  // console.log(blobobject);
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blobobject);
                  link.setAttribute("download", fileName);
                  link.click();
                  setLoading(false);
                  // link.download = "FileName" + new Date() + ".pdf";
                  // link.click();
                } else {
                  // console.log(
                  //  "issue with Download Report EndPoint in User APIs"
                  //);

                  setLoading(false);
                }
              });
            }
          } else {
            // console.log(
            //   "issue with Download Report EndPoint in Image Service APIs"
            // );
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("Issue with Reports API");
      // console.log(err);
    }
  };

  useEffect(() => {
    // loadjs("assets/js/app.js", () => { });
    loadjs("assets/js/vendor.min.js", () => {});

    // console.log(context.light);
    if (context.light) {
      let sheet = document.getElementById("bs-dark-stylesheet");
      sheet.disabled = true;
      let sheet1 = document.getElementById("app-dark-stylesheet");
      sheet1.disabled = true;
      let sheet2 = document.getElementById("bs-default-stylesheet");
      sheet2.disabled = false;
      let sheet3 = document.getElementById("app-default-stylesheet");
      sheet3.disabled = false;
    } else {
      let sheet = document.getElementById("bs-default-stylesheet");
      sheet.disabled = true;
      let sheet1 = document.getElementById("app-default-stylesheet");
      sheet1.disabled = true;
      let sheet2 = document.getElementById("bs-dark-stylesheet");
      sheet2.disabled = false;
      let sheet3 = document.getElementById("app-dark-stylesheet");
      sheet3.disabled = false;
    }
  }, [context.light]);

  const handlehamburgerMenu = () => {
    if (isActive === false) {
      document.body.setAttribute("data-sidebar-size", "condensed");
      setActive(true);
    } else {
      document.body.setAttribute("data-sidebar-size", "default");
      setActive(false);
    }
  };

  const handlebtnMaximize = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.body.requestFullscreen();
        document.body.setAttribute("data-sidebar-size", "condensed");
        setActive(true);
      } else if (document.documentElement.mozRequestFullScreen) {
        document.body.mozRequestFullScreen();
        document.body.setAttribute("data-sidebar-size", "condensed");
        setActive(true);
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.body.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        document.body.setAttribute("data-sidebar-size", "condensed");
        setActive(true);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const chnagelanguage = (ln) => {
    alert(ln);
    i18n.changeLanguage(ln);
  };

  const openChangeLangModal = (x) => {
    setOpenLangModal(x);
  };

  const handleChangeLanguage = (option) => {
    setSelectedLanguage(option);
    localStorage.setItem("language", option);
  };
  const callLanguageAPI = () => {
    try {
      context.resetLoading(true);
      // console.log("Calling Register User API");
      {
          localStorage.setItem("UserDefaultLanguage", selectedLanguage.value);
          if (selectedLanguage.value === "Hindi") {
            localStorage.setItem('omp_token', 'Hindi');
            i18n.changeLanguage("hi");
          } else {
            localStorage.setItem('omp_token', 'English');
            i18n.changeLanguage("en");
          }
          let horoscopeData =
            context.horoscopeData || localStorage.getItem("horoscopeData");
          let requestParamsHoroscope =
            context.requestParamsHoroscope ||
            localStorage.getItem("requestParamsHoroscope");
          // console.log(horoscopeData, requestParamsHoroscope);
          if (
            Object.keys(horoscopeData).length !== 0 &&
            Object.keys(requestParamsHoroscope).length !== 0
          ) {
            let gender = horoscopeData.basic_details.gender;
            let name = horoscopeData.native_name;
            let year = processDates(
              context.horoscopeData.date_of_birth
            ).getFullYear();
            let month =
              processDates(horoscopeData.date_of_birth).getMonth() + 1;

            let day = processDates(horoscopeData.date_of_birth).getDate();

            let hour = timeFormatter("hour", horoscopeData.time_of_birth);

            let min = timeFormatter("min", horoscopeData.time_of_birth);

            let sec = timeFormatter("sec", horoscopeData.time_of_birth);

            let ApiInstant = new Api();
            
            ApiInstant.fetchUserHoroscope(
              name,
              genderMapper[gender],
              day,
              month,
              year,
              hour,
              min,
              sec,
              requestParamsHoroscope.latitude,
              requestParamsHoroscope.longitude,
              requestParamsHoroscope.offset,
              requestParamsHoroscope.name,
              requestParamsHoroscope.state,
              requestParamsHoroscope.country,
              requestParamsHoroscope.reference_longitude_for_timezone,
              requestParamsHoroscope.timezone,
              localStorage.getItem("omp_token")
            )
              .then((result) => {
                context.setHoroscopeData(result.data);
                context.resetLoading(false);
                setOpenLangModal(false);
              })
              .catch((e) => {
                // console.log(e);
                context.resetLoading(false);
              });
              //Yoga Analyzer API call
              ApiInstant.fetchAnalyzeHoroscopeAPI(
                name,
                genderMapper[gender],
                day,
                month,
                year,
                hour,
                min,
                sec,
                requestParamsHoroscope.latitude,
                requestParamsHoroscope.longitude,
                requestParamsHoroscope.offset,
                requestParamsHoroscope.name,
                requestParamsHoroscope.state,
                requestParamsHoroscope.country,
                requestParamsHoroscope.reference_longitude_for_timezone,
                requestParamsHoroscope.timezone,
                localStorage.getItem("omp_token")
              )
                .then((result) => {
                  if (result.data) {
                    window.localStorage.setItem("requestYogaAnalyzer",JSON.stringify(result.data));
                    context.setYogaAnalyzerData(result.data);
                  }
                })
                .catch((e) => {
                  console.log(e);
                  context.resetLoading(false);
                });     
          } else {
            context.resetLoading(false);
            setOpenLangModal(false);
          }
        }
    } catch (err) {
      // console.log(
      //  "issue with Change Lang API Endpoint in User APIs. API link - http://apiv2.omparashar.com/user/api-doc/"
      //);
      // console.log(err);
    }
  };

  const handleReportChange = (option) => {
    setSelectedReport(option);
  };

  return (
    <>
      {/* <!-- Topbar Start --> */}
      <div className="navbar-custom">
        <div className="container-fluid">
          <ul className="list-unstyled topnav-menu float-right mb-0">
            <li className="dropdown d-inline-block d-lg-none">
              <a
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i className="fe-search noti-icon"></i>
              </a>
              <div className="dropdown-menu dropdown-lg dropdown-menu-right p-0">
                <form className="p-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                </form>
              </div>
            </li>

            {/* CHNAGE LANGUAGE */}
            {/* <li className="dropdown d-none d-lg-inline-block">
              <a
                id="english"
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="fullscreen"
                onClick={() => chnagelanguage("en")}
              >
                <i className="fe-globe "></i>
                EN
              </a>
            </li>
            <li className="dropdown d-none d-lg-inline-block">
              <a
                id="hindi"
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="fullscreen"
                onClick={() => chnagelanguage("hi")}
              >
                <i className="fe-globe "></i>
                HI
              </a>
            </li> */}
            
            {props.location.pathname.includes("horoscope") ? (
              <li
                className="dropdown d-none d-lg-inline-block"
                style={{ marginTop: "15px", marginRight: "10px" }}
              >
                <button
                  data-toggle="collapse"
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={() => setOpenReportsModal(true)}
                  disabled={loading}
                >
                  {loading ? (
                    <span>
                      {t("Downloading Report")}{" "}
                      <i className="mdi mdi-spin mdi-loading mr-1 font-14"></i>
                    </span>
                  ) : (
                    <span>{t("Download Horoscope PDF")}</span>
                  )}
                </button>
              </li>
            ) : (
              <></>
            )}
            {/* {props.location.pathname.includes("horoscope") ? (
              <li
                className="dropdown d-none d-lg-inline-block"
                style={{ marginTop: "15px", marginRight: "10px" }}
              >
                <button
                  data-toggle="collapse"
                  type="button"
                  className="btn btn-info waves-effect waves-light"
                  onClick={() => downloadHoroscopeReport()}
                  disabled={yogaLoading}
                >
                  {yogaLoading ? (
                    <span>
                      {t("Downloading Horoscope PDF")}{" "}
                      <i className="mdi mdi-spin mdi-loading mr-1 font-14"></i>
                    </span>
                  ) : (
                    <span>{t("Download Horoscope PDF")}</span>
                  )}
                </button>
              </li>
            ) : (
              <></>
            )} */}
            <li className="dropdown d-none d-lg-inline-block">
              <a
                id="fullscreen"
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="fullscreen"
                onClick={() => handlebtnMaximize()}
              >
                <i className="fe-maximize noti-icon"></i>
              </a>
            </li>
            {/* <li className="dropdown d-none d-lg-inline-block">
              <a
                id="changelang"
                className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                data-toggle="fullscreen"
                title="change language"
                onClick={() => openChangeLangModal(true)}
              >
               <i class="fa fa-language fa-2x noti-icon" aria-hidden="true"></i>
              </a>
            </li> */}

           
          </ul>

          <div className="logo-box">
            <Link to="/">
              <a href="" className="logo logo-dark text-center">
                <span className="logo-sm">
                  <img src="../assets/images/logo-sm.png" alt="" height="22" />
                  <span className="logo-lg-text-light">{t("Omparashar")}</span>
                </span>
                <span className="logo-lg">
                  <img
                    src="../assets/images/logo-dark.png"
                    alt=""
                    height="20"
                  />
                  <span className="logo-lg-text-light">OM</span>
                </span>
              </a>
            </Link>
            <Link to="/">
              <a href="" className="logo logo-light text-center">
                <span className="logo-sm">
                  <img src="../assets/images/logo-sm.png" alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img
                    src="../assets/images/logo-light.png"
                    alt=""
                    height="40"
                  />
                </span>
              </a>
            </Link>
          </div>

          <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
              <button
                className="button-menu-mobile waves-effect waves-light"
                onClick={() => handlehamburgerMenu()}
                style={{ display: "inline-block" }}
              >
                <i className="fe-menu"></i>
              </button>
            </li>

            <li>
              <a
                className="navbar-toggle nav-link"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </li>
            
          </ul>
          <center><b>
           <span style={{ color: 'white', fontSize: '24px', paddingTop: '16px', fontWeight: 'bolder',position:'absolute' }}> {celebName}</span>
          </b></center>
          
        </div>
      </div>

      <Modal show={openLangModal} onHide={() => setOpenLangModal(false)}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Please Change Language preference</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              Select Preferred Language:
              <Select
                value={selectedLanguage}
                options={languageOptions}
                onChange={handleChangeLanguage}
              />
            </label>
          </Modal.Body>
          <Modal.Footer>
            <center>
              <button
                type="submit"
                className="btn btn-primary font-16"
                style={{ width: "300px" }}
                onClick={callLanguageAPI}
                disabled={context.IsLoading}
              >
                {context.IsLoading ? (
                  <span>
                    {t("Getting Data")}{" "}
                    <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                  </span>
                ) : (
                  t("Confirm")
                )}
              </button>
            </center>
          </Modal.Footer>
        </>
      </Modal>

      <Modal show={openReportsModal} onHide={() => setOpenReportsModal(false)}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Please Chose Report Preference</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              Select Preferred Report:
              <Select
                value={selectedReport}
                options={reportOptions}
                onChange={handleReportChange}
              />
            </label>
          </Modal.Body>
          <Modal.Footer>
            <center>
              <button
                type="submit"
                className="btn btn-primary font-16"
                style={{ width: "300px" }}
                onClick={callReportAPIHandler}
                disabled={context.IsLoading}
              >
                {loading ? (
                  <span>
                    {t("Downloading")}{" "}
                    <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                  </span>
                ) : (
                  t("Download")
                )}
              </button>
            </center>
          </Modal.Footer>
        </>
      </Modal>

      {/* <!-- end Topbar -->*/}
    </>
  );
};

export default withRouter(DashboardTopBarCelebrity);
