import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
export const GlobalContext = React.createContext();
function pad(n) {
  return n < 10 ? "0" + n : n;
}
const date = new Date();
const time =
  pad(date.getHours()) +
  ":" +
  pad(date.getMinutes()) +
  ":" +
  pad(date.getSeconds());

export class GlobalProvider extends Component {
  planet_init = [
    {
      R: 195,
      r: 7,
      speed: -1.6,
      phi0: 35,
      color: "white",
      degree: -90,
      name: "Mo",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 285,
      r: 7,
      speed: -1.6,
      phi0: 35,
      color: "yellow",
      degree: -90,
      name: "Sa",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 210,
      r: 7,
      speed: -2.6,
      phi0: 135,
      color: "orange",
      degree: -90,
      name: "Su",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 225,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "skyblue",
      degree: -90,
      name: "Me",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 240,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "red",
      degree: -90,
      name: "Ve",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 255,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "maroon",
      degree: -90,
      name: "Ma",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 270,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "green",
      degree: -90,
      name: "Ju",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 300,
      r: 7,
      speed: -2.6,
      phi0: 25,
      color: "purple",
      degree: -90,
      name: "Ra",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 315,
      r: 7,
      speed: 1.6,
      phi0: 35,
      color: "grey",
      degree: -90,
      name: "Ke",
      motion: "",
      moons: [], // mercury
    },
  ];


  default_planet_init = [
    {
      R: 195,
      r: 7,
      speed: -1.6,
      phi0: 35,
      color: "white",
      degree: -90,
      name: "Mo",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 285,
      r: 7,
      speed: -1.6,
      phi0: 35,
      color: "yellow",
      degree: -90,
      name: "Sa",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 210,
      r: 7,
      speed: -2.6,
      phi0: 135,
      color: "orange",
      degree: -90,
      name: "Su",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 225,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "skyblue",
      degree: -90,
      name: "Me",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 240,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "red",
      degree: -90,
      name: "Ve",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 255,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "maroon",
      degree: -90,
      name: "Ma",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 270,
      r: 7,
      speed: -1.6,
      phi0: 25,
      color: "green",
      degree: -90,
      name: "Ju",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 300,
      r: 7,
      speed: -2.6,
      phi0: 25,
      color: "purple",
      degree: -90,
      name: "Ra",
      motion: "",
      moons: [], // mercury
    },
    {
      R: 315,
      r: 7,
      speed: 1.6,
      phi0: 35,
      color: "grey",
      degree: -90,
      name: "Ke",
      motion: "",
      moons: [], // mercury
    },
  ];


  format_incoming_data = (data, t) => {
    var api_data = data.p_dates;
    var array_format = this.planet_init;
    let new_array = [];
    // console.log("----test ------");
    api_data.forEach((name, i) => {
      array_format.forEach((a, j) => {
        var api_dgree = api_data[i].planets.find((b) => b.name === a.name);
        if (api_dgree) {
          a.degree = api_dgree.deg;
          //if(api_dgree.motion === "R"){
          a.motion = api_dgree.motion;

          //}
        }
      });
      new_array.push(JSON.parse(JSON.stringify(array_format)));
    });

    //console.log(new_array);
    return new_array;
  };



  play_array = (t) => {

    var formatted_array = this.format_incoming_data(
      this.state.newState_apidata,
      t
    );
    // console.log("formatted_Array", formatted_array);
    this.setState({ newStateplanet: formatted_array });
    this.setState({ showTable: true });
    //this.setState({apidataState:dummy_data.p_dates});
    // these fn go on page load or onmount state event

    var length = formatted_array.length;
    window.t = setInterval(() => {
      //set playicon to mdi mdi-pause
      this.setState({ playicon: "mdi mdi-pause" });
      //set the position based on array

      this.setState({ planet: formatted_array[this.state.current_index] });
      //set date value
      this.setState({
        apidataState:
          this.state.newState_apidata.p_dates[this.state.current_index],
      });
      // update the array index by 1 for next position
      this.setState({ current_index: this.state.current_index + 1 });

      if (this.state.current_index === length) {
        //console.log(this.state.current_index + " - " + length);
        clearInterval(window.t);
        this.setState({ playicon: "mdi mdi-play" });
        this.setState({ current_index: 0 });
      }
    }, this.state.play_speed * 1000);
  };

  pause_array = () => {
    clearInterval(window.t);
    this.setState({ playicon: "mdi mdi-play" });
  };

  forward_array = () => {
    var length = this.state.newStateplanet.length;
    // console.log(length )
    if (length > this.state.current_index) {
      // update the array index by 1 for next position
      this.setState({ current_index: this.state.current_index + 1 });

      //set the position based on array
      this.setState({
        planet: this.state.newStateplanet[this.state.current_index],
      });
      //set Side table
      this.setState({
        apidataState:
          this.state.newState_apidata.p_dates[this.state.current_index],
      });
    } else if (length === this.state.current_index) {
      clearInterval(window.t);
      this.setState({ playicon: "mdi mdi-play" });
      this.setState({ current_index: 0 });
    }
  };

  backward_array = () => {
    var length = this.state.newStateplanet.length;

    if (this.state.current_index === 0) {
      this.setState({ current_index: length });
      //set the position based on array
      this.setState({
        planet: this.state.newStateplanet[this.state.current_index],
      });
      //set Side table
      this.setState({
        apidataState:
          this.state.newState_apidata.p_dates[this.state.current_index],
      });
    } else {
      if (length > this.state.current_index) {
        // update the array index by 1 for next position
        this.setState({ current_index: this.state.current_index - 1 });
        //set the position based on array
        this.setState({
          planet: this.state.newStateplanet[this.state.current_index],
        });
        //set Side table
        this.setState({
          apidataState:
            this.state.newState_apidata.p_dates[this.state.current_index],
        });
      }
    }
  };

  playSpeed = (sec) => {
    this.setState({ play_speed: sec });
  };

  changeView = (value) => {
    // alert(value);
    this.setState({ currentView: value });
  };

  changeSideTableDisplay = (value) => {
    // alert(value);
    this.setState({ sideTableDisplay: value });
  };

  set_PanchangDate = (date, city) => {
    this.setState({ IsLoading: true });
    this.setState({ panchangDate: date });

    this.setState({ IsLoading: false });
  };

  call_daterange = (url, city, str_date, end_date) => {
    var config = {
      method: "get",
      url: url,
      headers: {},
    };
    this.setState({ IsLoading: true });
    // always use arrow function otherwise this. will not work
    return axios(config)
      .then((response) => {
        //set for SIDETABLE
        // console.log(response.data);
        this.setState({
          newState_apidata: response.data,
          startDate: str_date,
          endDate: end_date,
        });
        window.localStorage.setItem("startDate", JSON.stringify(str_date));
        window.localStorage.setItem("endDate", JSON.stringify(end_date));
        window.localStorage.setItem("APIData", JSON.stringify(response.data));
        this.setState({ IsActive: "btn btn-dark waves-effect" });
        // console.log(response.data);
        return true;
        //console.log("Result"+JSON.stringify(response.data));
        //console.log(api_data);
      })
      .catch(function (error) {
        // console.log("Result" + error);
        this.setState({ isLoading: false });
      });
  };

  reset_form_for_journey = () => {
    this.setState({ repositories: [] });
  };

  setStartOrEndDate = (param, date) => {
    if (param === "start") {
      this.setState({ startDate: date });
    } else {
      this.setState({ endDate: date });
    }
  };
  setPlanetConjunctionStartorEnd = (param, year) => {
    if (param === "start") {
      this.setState({ planetConjunctionStart: year });
      window.localStorage.setItem("conjunctionStartYear", year);
    } else {
      this.setState({ planetConjunctionEnd: year });
      window.localStorage.setItem("conjunctionEndYear", year);
    }
  };

  setSelectedConjunctionPlanets = (planetArray) => {
    this.setState({ selectedConjunctionArray: planetArray });
    window.localStorage.setItem("selectedConjunctionArray", JSON.stringify(planetArray));
  }

  setPlanetaryConjunctionAPIData = (data) => {
    this.setState({ planetaryConjunctionAPIData: data });
    window.localStorage.setItem("planetaryConjunction", JSON.stringify(data));
  }

  setSelectedRashiArray = (data) => {
    this.setState({ selectedRashiArray: data });
    window.localStorage.setItem("selectedRashiArray", JSON.stringify(data));
  }

  setAPIData = (data) => {
    this.setState({
      newState_apidata: data,
    });
  };

  reset_loading = (value) => {
    this.setState({ IsLoading: value });
  };

  AreDetailsFilled = () => {
    if (
      // this.state.requestParams === {} ||
      this.state.requestParams.length === 0 ||
      this.state.startDate === null ||
      this.state.endDate === null
    ) {
      return false;
    } else {
      return true;
    }
  };

  HandleDetailsFormClose = (param) => {
    this.setState({ showSideFormTransition: param });
  };

  SetLight = (param) => {
    this.setState({ light: param });
  };

  setHoroscopeData = (json) => {
    this.setState({ horoscopeData: json });
    window.localStorage.setItem("horoscopeData", JSON.stringify(json));
  };
  setYogaAnalyzerData = (json) => {
    this.setState({ yogaAnalyzerData: json });
    window.localStorage.setItem("requestYogaAnalyzer", JSON.stringify(json));
  };
  setCelebrityManagementData = (json) => {
    this.setState({ eventManagementData: json });
    window.localStorage.setItem("requestEventManagement", JSON.stringify(json));
  };
  setRequestParams = (param) => {
    this.setState({ requestParams: param });
    window.localStorage.setItem("requestParams", JSON.stringify(param));
  };

  setRequestParamsHoroscope = (param) => {
    this.setState({ requestParamsHoroscope: param });
    window.localStorage.setItem(
      "requestParamsHoroscope",
      JSON.stringify(param)
    );
  };
  setRequestParamsPanchang = (param) => {
    this.setState({ requestParamsPanchang: param });
    window.localStorage.setItem("requestParamsPanchang", JSON.stringify(param));
  };

  SetCityAndState = (cityState, mode) => {
    if (mode === "Horoscope") {
      this.setState({ horoscopeCityState: cityState });
    } else if (mode === "Panchang") {
      this.setState({ panchangCityState: cityState });
    } else if (mode === "Transit") {
      this.setState({ transitCityState: cityState });
    }
  };

  SetCountry = (country, mode) => {
    if (mode === "Horoscope") {
      this.setState({ horoscopeCountry: country });
    } else if (mode === "Panchang") {
      this.setState({ panchangCountry: country });
    } else if (mode === "Transit") {
      this.setState({ transitCountry: country });
    }
  };

  SetCityId = (cityId) => {
    this.setState({ cityId: cityId });
  };

  setLightAndDarkMode = (param) => {
    this.setState({ lightDarkMode: param });
  };

  clearLocalStorage = () => {
    window.localStorage.clear();
  };

  handleHoroscopeForm = (param1, param2) => {
    if (param2 === "open") {
      this.setState({ horoscopeForm: param1 });
    } else {
      this.setState({ horoscopeForm: param1 });
    }
  };

  handleCelebrityManagementForm = (param1, param2) => {

    this.setState({ CelebrityManagementForm: "" })
  };

  handlePanchangForm = (param) => {
    this.setState({ panchangForm: param });
  };

  setHoroscopePlaceDetails = (str) => {
    this.setState({ horoscopePlaceDetails: str });
  };

  SetGoogleAuthObj = (obj) => {
    this.setState({ googleAuthObj: obj });
  };

  setUserDBObject = (obj) => {
    this.setState({ userDBObject: obj });
  };

  handleShow = (val) => {
    // console.log("In Handle Show" + val);
    this.setState({ currentPage: val });
    this.setState({ loginModalShow: true });
  };

  closeLoginModal = () => {
    this.setState({ loginModalShow: false });
  };

  setTokenExpiredCounter = () => {
    // console.log("increased count");
    this.setState({ tokenExpiredCount: this.state.tokenExpiredCount + 1 });
  };

  defaultPlanet = () => {
    this.setState({ planet: this.default_planet_init })
  };

  setlongTermJourneyStartorEndDate = (param, date) => {
    if (param === "Start") {
      this.setState({ longTermJourneyStartDate: date })
      localStorage.setItem("longTermJourneyStartDate", date)
    }
    else {
      this.setState({ longTermJourneyEndDate: date })
      localStorage.setItem("longTermJourneyEndDate", date)
    }
  };

  setLongTermJourneyPlanet = (planet) => {
    this.setState({ longTermJourneyPlanet: planet })
    localStorage.setItem("longTermJourneyPlanet", planet)
  };
  setLongTermJourneyAPIData=(data)=>{
    this.setState({longTermJourneyAPIData:data});
    localStorage.setItem("longTermJourneyAPIData",JSON.stringify(data))
  }
  state = {
    planet: this.planet_init,
    defaultPlanet: () => this.defaultPlanet(),
    showTable: false,
    newStateplanet: "", // updated planet state after api call
    apidataState: "", // set this
    newState_apidata: "",
    repositories: [],
    current_index: 0,
    IsActive: "btn btn-dark waves-effect disabled", // class used for wheel buttons
    currentView: "Dashboard",
    change_View: (value) => this.changeView(value),
    playicon: "mdi mdi-play",
    playplanet: (x) => this.play_array(x),
    play_speed_fn: (seconds) => this.playSpeed(seconds),
    play_speed: 1,
    pauseplanet: this.pause_array,
    forwardPlanet: this.forward_array,
    backwardPlanet: this.backward_array,
    callAPI_daterange: (url, city, str_date, end_date) =>
      this.call_daterange(url, city, str_date, end_date),
    togglebutton: this.toggle,
    //Faraaz used the above states. Should ask him to comment
    requestParams: {}, // to store details of city fetched from Atlas in transition
    setRequestParams: (param) => this.setRequestParams(param), // corresponding setter for requestParams
    requestParamsHoroscope: {}, // to store details of city fetched from Atlas in horoscope
    setRequestParamsHoroscope: (param) => this.setRequestParamsHoroscope(param), // corresponding setter for requestParams
    requestParamsPanchang: {}, // to store details of city fetched from Atlas in transition
    setRequestParamsPanchang: (param) => this.setRequestParamsPanchang(param), // corresponding setter for requestParams
    horoscopeCityState: null, // city selected from autocomplete in side form
    horoscopeCountry: null, // country selected from autocomplete in side form
    panchangCityState: null, // city selected from autocomplete in side form
    panchangCountry: null, // country selected from autocomplete in side form
    transitCityState: null, // city selected from autocomplete in side form
    transitCountry: null, // country selected from autocomplete in side form
    setCityAndState: (cityState, mode) => this.SetCityAndState(cityState, mode),
    setCountry: (country, mode) => this.SetCountry(country, mode),

    startDate: null, // startDate for Transition Components
    endDate: null, // endDate for Transition Components
    setStartOrEndDate: (a, b) => this.setStartOrEndDate(a, b),

    planetConjunctionStart: null,
    planetConjunctionEnd: null,
    setPlanetConjunctionStartorEnd: (a, b) => this.setPlanetConjunctionStartorEnd(a, b),
    selectedConjunctionArray: [],
    setSelectedConjunctionPlanets: (arr) => this.setSelectedConjunctionPlanets(arr),
    planetaryConjunctionAPIData: null,
    setPlanetaryConjunctionAPIData: (data) => this.setPlanetaryConjunctionAPIData(data),
    selectedRashiArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    setSelectedRashiArray: (data) => this.setSelectedRashiArray(data),

    longTermJourneyStartDate: null,
    longTermJourneyEndDate: null,
    setlongTermJourneyStartorEndDate: (param, date) => this.setlongTermJourneyStartorEndDate(param, date),
    longTermJourneyPlanet: null,
    setLongTermJourneyPlanet: (planet) => this.setLongTermJourneyPlanet(planet),
    longTermJourneyAPIData:null,
    setLongTermJourneyAPIData:(data)=>this.setLongTermJourneyAPIData(data),

    setAPIData: (a) => this.setAPIData(a),
    IsLoading: false, // Loader in sideform button
    resetLoading: (value) => this.reset_loading(value), // Corresponding setter
    sideTableDisplay: false, // side table display flag in galactic view
    changeSideTableDisplay: (value) => this.changeSideTableDisplay(value), // Corresponding setter
    resetForm: () => this.reset_form_for_journey(), // function to reset form in transition components
    panchang_date_change: (value) => this.operations_panchang_date(value), // function to move panchange date next prev.
    areDetailsFilled: () => this.AreDetailsFilled(), // checking whether sideform is filled
    showSideFormTransition: false, // form modal display flag
    handleSideFormTransition: (param) => this.HandleDetailsFormClose(param), // corresponding setter
    horoscopeData: {}, // 30,000 lines json used for all horoscope components
    setHoroscopeData: (json) => this.setHoroscopeData(json), // corresponding setter
    yogaAnalyzerData: {},
    setYogaAnalyzerData: (json) => this.setYogaAnalyzerData(json),
    celebrityManagementData: {},
    setCelebrityManagementData: (json) => this.setCelebrityManagementData(json), // corresponding setter

    light: true, // light mode flag
    setLight: (param) => this.SetLight(param),
    clearLocalStorage: () => this.clearLocalStorage(),
    horoscopeForm: false,
    handleHoroscopeForm: (a, b) => this.handleHoroscopeForm(a, b),
    panchangForm: false,
    handlePanchangForm: (a) => this.handlePanchangForm(a),
    CelebrityManagementForm: false,
    handleCelebrityManagementForm: (a, b) => this.handleCelebrityManagementForm(a, b),
    googleAuthObj: null,
    setGoogleAuthObj: (obj) => this.SetGoogleAuthObj(obj),
    loginModalShow: false,
    currentpage: "/",
    handleShow: (val) => this.handleShow(val),
    closeLoginModal: () => this.closeLoginModal(),
    tokenExpiredCount: 0,
    setTokenExpiredCounter: () => this.setTokenExpiredCounter(),
  };

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}



