import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import RelationTable from "./RelationTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import NatalChart from "./Natal_chart";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + name + ".png";
}

const ValueMapper = {
  1: "Prasthar",
  2: "Sarvashtak",
  3: "Bhinnashtak",
};

const radios = [
  { name: "Sun", value: "1" },
  { name: "Moon", value: "2" },
  { name: "Mars", value: "3" },
  { name: "Mercury", value: "4" },
  { name: "Jupiter", value: "5" },
  { name: "Venus", value: "6" },
  { name: "Saturn", value: "7" },
  { name: "Asc", value: "8" },
];

const sarvashtaks = [
  { name: "Before Trikona Reduction", value: "1" },
  { name: "After Trikona Reduction", value: "2" },
  { name: "After EK Reduction", value: "3" },
  { name: "Shodya Pinda", value: "4" },
];

const types = [
  { name: "Bindu", value: "1" },
  { name: "Rekha", value: "2" },
];

const typesBin = [
  { name: "Bindu", value: "1" },
  { name: "Rekha", value: "2" },
  { name: "Net", value: "3" },
];

const sarvTitleMapper = {
  1: "Before Trikona Reduction",
  2: "After Trikona Reduction",
  3: "After EK reduction",
  4: "Shodya Pinda",
};

const ShodyaMapper = {
  2: "Shodya Pinda",
  1: "Graha Pinda",
  0: "Rashi Pinda",
};

const planetMapper = {
  1: "Sun",
  2: "Moon",
  3: "Mars",
  4: "Mercury",
  5: "Jupiter",
  6: "Venus",
  7: "Saturn",
  8: "Asc",
};

const typeMapper = {
  1: "Bindu",
  2: "Rekha",
  3: "Net",
};

const sarvMapper = {
  1: "samudaya_ashtak_varga",
  2: "samudaya_ashtak_varga_after_trikona_reduction",
  3: "samudaya_ashtak_varga_after_EK_reduction",
  4: "shodya_pinda",
};

const RashiMapper = {
  1: "Ari",
  2: "Tau",
  3: "Gem",
  4: "Can",
  5: "Leo",
  6: "Vir",
  7: "Lib",
  8: "Sco",
  9: "Sag",
  10: "Cap",
  11: "Aqu",
  12: "Pis",
};
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootCard: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5%",
  },
  CardContent: {
    backgroundColor: "rgb(90,135,34)",
    color: "#fff",
  },
  PlanetaryRelationDiv: {
    position: "relative",
    background: "#fff",

    maxHeight: "inherit",
    maxWidth: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  searchDiv: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "5%",
  },
  resultdiv: {
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: "300px",
  },
  tab: {
    color: "grey",
  },
});

class AstakVarga extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      value: 1,
      relation: this.props.relation || "Prasthar",
      tab: "Samudaya",
      radioValue: "1",
      radioName: "Sun",
      typeValue: "1",
      sarvValue: "1",
      typeBinValue: "1",
      chartPlanetBhinValue: "1",
      chartPlanetBhinName: "Sun",
      chartData: [],
      chartBhinData: [],
      viewTypeSarvashtak: "table",
      viewBhin: this.props.viewBhin || "table",
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.FilterData = this.FilterData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
    let queryString = getQueryParam('cb');
    if(queryString){
      // alert(queryString);
       this.setState({cb_view:true});
       this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
 
       this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});

       let horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
       this.FilterData(horoscopeData);
       this.filterChartBhinnashtakData(horoscopeData);
     }else{

     

    let horoscopeData = JSON.parse(
      window.localStorage.getItem("horoscopeData")
    );
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (
      requestParams !== null &&
      Object.keys(this.context.requestParams).length === 0
    ) {
      this.context.setRequestParamsHoroscope(requestParams);
    }
    if (
      horoscopeData !== null &&
      Object.keys(this.context.horoscopeData).length === 0
    ) {
      this.context.setHoroscopeData(horoscopeData);
    }
    if (horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.FilterData(horoscopeData);
      this.filterChartBhinnashtakData(horoscopeData);
    }
  }
  }

  componentDidUpdate(prevProps, prevState) {
    // First check props changes
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.FilterData(this.props.horoscopeData);
      this.filterChartBhinnashtakData(this.props.horoscopeData);
    }
  
    // Then check localStorage changes, but only if state hasn't been updated yet
    const storedHoroscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    if (
      storedHoroscopeData &&
      JSON.stringify(storedHoroscopeData.basic_details) !== JSON.stringify(this.state.horoscopeData.basic_details) &&
      JSON.stringify(prevState.horoscopeData) === JSON.stringify(this.state.horoscopeData)
    ) {
      this.setState({ horoscopeData: storedHoroscopeData });
      this.FilterData(storedHoroscopeData);
      this.filterChartBhinnashtakData(storedHoroscopeData);
    }
  }

  FilterData(horoscopeData, extraparam) {
    let data = horoscopeData.ashtak_varga;
    if (this.state.relation === "Prasthar") {
      let reqData = data.sarvashtak_vargas;
      let planetData =
        reqData[planetMapper[this.state.radioValue]][
          typeMapper[this.state.typeValue]
        ];
      let mainjson = [];
      let keys = Object.keys(planetData);
      for (let i in keys) {
        let json = {};
        json = planetData[keys[i]];
        json["Main Planet"] = keys[i];
        mainjson.push(json);
      }
      

      this.setState({ tableData: mainjson });
      var reqChartData = mainjson[8];
      var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const mainChart = [];
      for (let i of arr) {
        const res = {};
        res["rashi_num"] = i;
        res["list"] = reqChartData[RashiMapper[i]];
        mainChart.push(res);
      };

      //old
      // mainChart.unshift(mainChart.pop());
      // mainChart.unshift(mainChart.pop());
      //let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
      //let first_x_elements=mainChart.slice(0, x + 1)
      //let remaining_elements=mainChart.slice(x + 1, 12)
      
      //new 
     let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
     x<12 && mainChart.unshift(mainChart.pop());
     x<12 && mainChart.unshift(mainChart.pop());
     let first_x_elements=x<12 ? mainChart.slice(0, x + 1):mainChart.slice(0,x-1);
     let remaining_elements=x<12 ? mainChart.slice(x + 1, 12):mainChart.slice(x-1,12);
      
      // Destructuring to create the desired array
      let mainArr = [...remaining_elements, ...first_x_elements];
     
      this.setState({ chartDataPrasthar: mainArr });
    } else if (this.state.relation === "Sarvashtak") {
      if (this.state.sarvValue === "4") {
        let requiredData = data[sarvMapper[this.state.sarvValue]];
        let mainjson = [];
        for (let i in requiredData) {
          let json = {};
          for (let j in requiredData[i]) {
            json["Header"] = ShodyaMapper[i];
            for (let k in requiredData[i][j]) {
              json[k] = requiredData[i][j][k];
            }
          }
          mainjson.push(json);
        }

        this.setState({ tableData: mainjson });
      } else {
        let planetData = data[sarvMapper[this.state.sarvValue]];
        let mainjson = [];
        let keys = Object.keys(planetData);
        for (let i in keys) {
          let json = {};
          json = planetData[keys[i]];
          json["Main Planet"] = keys[i];
          mainjson.push(json);
        }
        this.setState({ tableData: mainjson });
        let reqChartData = mainjson[7];
        let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const mainChart = [];
        for (let i of arr) {
          const res = {};
          res["rashi_num"] = i;
          res["list"] = reqChartData[RashiMapper[i]];
          mainChart.push(res);
        }
        // mainChart.unshift(mainChart.pop());
        // mainChart.unshift(mainChart.pop());
        // let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
        // let first_x_elements = mainChart.slice(0, x + 1);
        // let remaining_elements = mainChart.slice(x + 1, 12);

        let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
     x<12 && mainChart.unshift(mainChart.pop());
     x<12 && mainChart.unshift(mainChart.pop());
     let first_x_elements=x<12 ? mainChart.slice(0, x + 1):mainChart.slice(0,x-1);
     let remaining_elements=x<12 ? mainChart.slice(x + 1, 12):mainChart.slice(x-1,12);

        // Destructuring to create the desired array
        let mainArr = [...remaining_elements, ...first_x_elements];
        this.setState({ chartData: mainArr });
      }
    } else {
      let requiredData = data.bhinnashtak_vargas;
      let mainjson = [];
      let keys = Object.keys(requiredData);
      for (let i in keys) {
        let json = {};
        json = { ...requiredData[keys[i]] }[
          typeMapper[this.state.typeBinValue]
        ];
        json["Main Planet"] = keys[i];
        mainjson.push(json);
      }
      this.setState({ tableData: mainjson });
    }
  }

  filterChartBhinnashtakData(horoscopeData) {
    let requiredData = horoscopeData.ashtak_varga.bhinnashtak_vargas;
    let mainjson = [];
    let finalData =
      requiredData[planetMapper[this.state.chartPlanetBhinValue]]["Net"];
    let count = 1;
    for (let i in finalData) {
      if (count > 12) {
        break;
      }
      let json = {};
      json["rashi_num"] = count;
      json["list"] = finalData[i];
      mainjson.push(json);
      count = count + 1;
    }

    // mainjson.unshift(mainjson.pop());
    // mainjson.unshift(mainjson.pop());
    // let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
    // let first_x_elements = mainjson.slice(0, x + 1);
    // let remaining_elements = mainjson.slice(x + 1, 12);

    let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
     x<12 && mainjson.unshift(mainjson.pop());
     x<12 && mainjson.unshift(mainjson.pop());
     let first_x_elements=x<12 ? mainjson.slice(0, x + 1):mainjson.slice(0,x-1);
     let remaining_elements=x<12 ? mainjson.slice(x + 1, 12):mainjson.slice(x-1,12);

    // Destructuring to create the desired array
    let mainArr = [...remaining_elements, ...first_x_elements];
    this.setState({ chartBhinData: mainArr });
  }

  handleChange = (newValue) => {
    this.setState({ value: newValue, relation: ValueMapper[newValue] }, () =>
      this.FilterData(this.state.horoscopeData)
    );
  };

  changePlanet = (value, name) => {
    this.setState({ radioValue: value, radioName: name }, () =>
      this.FilterData(this.state.horoscopeData)
    );
  };

  changeType = (value) => {
    this.setState({ typeValue: value }, () =>
      this.FilterData(this.state.horoscopeData)
    );
  };

  changeTypeBin = (value) => {
    this.setState({ typeBinValue: value }, () =>
      this.FilterData(this.state.horoscopeData)
    );
  };

  changeSarvType = (value) => {
    this.setState({ sarvValue: value }, () =>
      this.FilterData(this.state.horoscopeData)
    );
  };

  render() {
    const { classes } = this.props;
    const { tableData } = this.state;
    const { t, i18n } = this.props;
    return (
      <>
        <Sideform
          show={this.context.horoscopeForm}
          mode={"Horoscope"}
          view={"astakVarga"}
          handleClose={this.context.handleHoroscopeForm}
          reset={false}
          history={this.props.history}
        />
        <div className={[classes.PlanetaryRelationDiv, ""].join(" ")}>
          <ResetButton />
          <div className="panel with-nav-tabs panel-warning">
            <div className="panel-heading">
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.value}
                onSelect={(k) => this.handleChange(k)}
              >
                <Tab
                  className={classes.tab}
                  eventKey={1}
                  title={t("Prasthar")}
                ></Tab>
                <Tab
                  className={classes.tab}
                  eventKey={2}
                  title={t("Sarvashtak")}
                ></Tab>
                <Tab
                  className={classes.tab}
                  eventKey={3}
                  title={t("Bhinnashtak")}
                ></Tab>
              </Tabs>
            </div>
            {Object.keys(this.state.horoscopeData).length > 0 ? (
              <div>
                {this.state.relation === "Prasthar" ? (
                  <>
                    <div className="planetOptions">
                      <p className="header">{t("Select Planet")}:</p>
                      <ButtonGroup>
                        {radios.map((radio, idx) => (
                          <Button
                            key={radio + idx}
                            variant="outline-primary"
                            className={`${
                              this.state.radioValue === radio.value
                                ? "filled-button-planet"
                                : "planetButton"
                            }`}
                            onClick={() =>
                              this.changePlanet(radio.value, radio.name)
                            }
                          >
                            {t(radio.name)}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                    <div className="row">
                      <div className="col-md-5 col-12">
                        <p className="header" style={{ marginLeft: "16px" }}>
                          {t("Select Type")}:
                        </p>
                        <ButtonGroup style={{ marginLeft: "16px" }}>
                          {types.map((type, idx) => (
                            <Button
                              key={type + idx}
                              variant="outline-primary"
                              className={`${
                                this.state.typeValue === type.value
                                  ? "filled-button-type"
                                  : "typeButton"
                              }`}
                              onClick={() => this.changeType(type.value)}
                            >
                              {t(type.name)}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                      <div className="col-md-7 col-12 pl-5">
                        <span className="PlanetsName">
                          {this.state.radioName === "Sun" ? (
                            <>
                              <img
                                className="PlanetImages"
                                alt={this.state.radioName}
                                src={extract_image_link(this.state.radioName)}
                              ></img>
                              <span className="planet-name pt-1">
                                {t(this.state.radioName)}
                              </span>
                            </>
                          ) : this.state.radioName === "Asc" ? (
                            <></>
                          ) : (
                            <div>
                              <img
                                className="PlanetImages"
                                alt={this.state.radioName}
                                src={extract_image_link(this.state.radioName)}
                              ></img>
                              <span className="planet-name pt-1">
                                {t(this.state.radioName)}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                ) : this.state.relation === "Sarvashtak" ? (
                  <>
                    <div className="planetOptions">
                      <p className="header">{t("Select Mode")}:</p>
                      <ButtonGroup>
                        {sarvashtaks.map((sarv, idx) => (
                          <Button
                            style={{ whiteSpace: "nowrap" }}
                            key={sarv + idx}
                            variant="outline-primary"
                            className={`${
                              this.state.sarvValue === sarv.value
                                ? "filled-button-planet"
                                : "planetButton"
                            }`}
                            onClick={() => this.changeSarvType(sarv.value)}
                          >
                            {t(sarv.name)}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.viewBhin === "table" ? (
                      <div className="typeOptions">
                        <p className="header">{t("Select Type")}:</p>
                        <ButtonGroup>
                          {typesBin.map((type, idx) => (
                            <Button
                              key={type + idx}
                              variant="outline-primary"
                              className={`${
                                this.state.typeBinValue === type.value
                                  ? "filled-button-planet"
                                  : "planetButton"
                              }`}
                              onClick={() => this.changeTypeBin(type.value)}
                            >
                              {t(type.name)}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    ) : (
                      <div className="planetOptions">
                        <p className="header">{t("Select Planet")}:</p>
                        <ButtonGroup>
                          {radios.map((radio, idx) => (
                            <Button
                              key={radio + idx}
                              variant="outline-primary"
                              className={`${
                                this.state.chartPlanetBhinValue === radio.value
                                  ? "filled-button-planet"
                                  : "planetButton"
                              }`}
                              onClick={() =>
                                this.setState(
                                  {
                                    chartPlanetBhinValue: radio.value,
                                    chartPlanetBhinName: radio.name,
                                  },
                                  () =>
                                    this.filterChartBhinnashtakData(
                                      this.state.horoscopeData
                                    )
                                )
                              }
                            >
                              {t(radio.name)}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    )}
                    <div className="row typeOptions">
                      <div className="col-md-5 col-12 pl-0">
                        <p className="header">{t("Select View")}:</p>
                        <Button
                          variant="outline-primary"
                          className={`${
                            this.state.viewBhin === "table"
                              ? "filled-button-type"
                              : "typeButton"
                          }`}
                          onClick={() =>
                            this.setState({ viewBhin: "table" }, () =>
                              this.FilterData(this.state.horoscopeData)
                            )
                          }
                        >
                          {" "}
                          {t("Tabular View")}
                        </Button>
                        <Button
                          variant="outline-primary"
                          className={`${
                            this.state.viewBhin === "chart"
                              ? "filled-button-type"
                              : "typeButton"
                          }`}
                          onClick={() =>
                            this.setState({ viewBhin: "chart" }, () =>
                              this.filterChartBhinnashtakData(
                                this.state.horoscopeData
                              )
                            )
                          }
                          disabled={
                            this.state.relation === "Bhinnashtak" ? false : true
                          }
                        >
                          {" "}
                          {t("Chart View")}
                        </Button>
                      </div>
                      <div className="col-md-7 col-12 pl-5">
                        {this.state.viewBhin === "chart" ? (
                          <span className="PlanetsName">
                            {this.state.chartPlanetBhinName === "Sun" ? (
                              <>
                                <img
                                  className="PlanetImages"
                                  alt={this.state.chartPlanetBhinName}
                                  src={extract_image_link(
                                    this.state.chartPlanetBhinName
                                  )}
                                ></img>
                                <span className="planet-name pt-1">
                                  {t(this.state.chartPlanetBhinName)}
                                </span>
                              </>
                            ) : this.state.chartPlanetBhinName === "Asc" ? (
                              <></>
                            ) : (
                              <div>
                                <img
                                  className="PlanetImages"
                                  alt={this.state.chartPlanetBhinName}
                                  src={extract_image_link(
                                    this.state.chartPlanetBhinName
                                  )}
                                ></img>
                                <span className="planet-name pt-1">
                                  {t(this.state.chartPlanetBhinName)}
                                </span>
                              </div>
                            )}
                          </span>
                        ) : (
                          ""
                        )}{" "}
                      </div>
                    </div>
                  </>
                )}
                {this.state.relation === "Bhinnashtak" &&
                this.state.viewBhin === "chart" ? (
                  <>
                    <div
                      className="col-md-5"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    >
                      <NatalChart
                        className="svg1"
                        id="container1"
                        data={this.state.chartBhinData}
                        rightMargin="auto"
                        h="550"
                        relation={this.state.relation}
                      />
                    </div>
                  </>
                ) : this.state.relation === "Prasthar" ? (
                  <>
                    <div className="row">
                      <div className="col-md-7">
                        <RelationTable
                          data={tableData}
                          relation={this.state.relation}
                          sarvValue={this.state.sarvValue}
                          typeBinValue={this.state.typeBinValue}
                          selectedPlanetpr={
                            this.state.relation === "Prasthar"
                              ? planetMapper[this.state.radioValue]
                              : ""
                          }
                          selectedTypepr={
                            this.state.relation === "Prasthar"
                              ? typeMapper[this.state.typeValue]
                              : ""
                          }
                          selectedModesarv={
                            this.state.relation === "Sarvashtak"
                              ? sarvTitleMapper[this.state.sarvValue]
                              : ""
                          }
                          selectedTypebin={
                            this.state.relation === "Bhinnashtak"
                              ? typeMapper[this.state.typeBinValue]
                              : ""
                          }
                        />
                      </div>
                      <div className="col-md-5 ">
                        
                        <NatalChart
                          className="svg1"
                          id="container1"
                          data={this.state.chartDataPrasthar}
                          rightMargin=""
                          h="550"
                        />
                      </div>
                    </div>
                  </>
                ) : this.state.relation === "Sarvashtak" &&
                  this.state.sarvValue !== "4" ? (
                  <>
                    <div className="row">
                      <div className="col-md-7">
                        <RelationTable
                          data={tableData}
                          relation={this.state.relation}
                          sarvValue={this.state.sarvValue}
                          typeBinValue={this.state.typeBinValue}
                          selectedPlanetpr={
                            this.state.relation === "Prasthar"
                              ? planetMapper[this.state.radioValue]
                              : ""
                          }
                          selectedTypepr={
                            this.state.relation === "Prasthar"
                              ? typeMapper[this.state.typeValue]
                              : ""
                          }
                          selectedModesarv={
                            this.state.relation === "Sarvashtak"
                              ? sarvTitleMapper[this.state.sarvValue]
                              : ""
                          }
                          selectedTypebin={
                            this.state.relation === "Bhinnashtak"
                              ? typeMapper[this.state.typeBinValue]
                              : ""
                          }
                        />
                      </div>
                      
                      <div className="col-md-5 pt-2">
                        <NatalChart
                          className="svg1"
                          id="container1"
                          data={this.state.chartData}
                          rightMargin=""
                          h="550"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <RelationTable
                    data={tableData}
                    relation={this.state.relation}
                    sarvValue={this.state.sarvValue}
                    typeBinValue={this.state.typeBinValue}
                    selectedPlanetpr={
                      this.state.relation === "Prasthar"
                        ? planetMapper[this.state.radioValue]
                        : ""
                    }
                    selectedTypepr={
                      this.state.relation === "Prasthar"
                        ? typeMapper[this.state.typeValue]
                        : ""
                    }
                    selectedModesarv={
                      this.state.relation === "Sarvashtak"
                        ? sarvTitleMapper[this.state.sarvValue]
                        : ""
                    }
                    selectedTypebin={
                      this.state.relation === "Bhinnashtak"
                        ? typeMapper[this.state.typeBinValue]
                        : ""
                    }
                  />
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles, { withTheme: true })(AstakVarga)
);
