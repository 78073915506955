import React from "react";
import { Link } from "react-router-dom";
import { PiPlanetFill } from "react-icons/pi";
import {
  FiAperture,
  FiActivity,
  FiCircle,
  FiCodesandbox,
} from "react-icons/fi";
import {
  BsBullseye,
  BsColumns,
  BsFillBrightnessHighFill,
  BsBrilliance
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

const TransitionDashboard = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <li className="menu-title mt-2">{t("Workspace")}</li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("galacticView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/galacticView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/galacticView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="life-buoy"></i> */}
          <FiAperture />
          <span>{t("Galactic View")}</span>
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("tracesView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/tracesView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/tracesView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FiCodesandbox />
          <span>{t("Traces View")}</span>{" "}
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("journeyView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/journeyView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/journeyView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-commit"></i> */}
          <FiActivity />
          <span>{t("Planet Journey")}</span>{" "}
        </Link>
      </li>
      
      <li>
          <Link
          data-toggle="collapse"
          onClick={() => props.handleView("longtermjourney")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/longtermjourney"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/longtermjourney"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-commit"></i> */}
          <PiPlanetFill />
          <span>{t("Long Term Planet Journey")}</span>
        </Link>
          </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("snapshotView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/snapshotView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/snapshotView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="layout"></i> */}
          <BsColumns />
          <span>{t("Snapshot Now")}</span>{" "}
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("kaalPurushView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/kaalPurushView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/kaalPurushView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="triangle"></i> */}
          <FiCircle />
          <span>{t("Transit for Kaal Purush")}</span>
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("sunAndMoonView")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/sunAndMoonView"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/sunAndMoonView"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-commit"></i> */}
          <BsFillBrightnessHighFill />
          <span>{t("Sun and Moon Phases")}</span>
        </Link>
      </li>
      <li>
          {/* <a href="/dashboard/planetaryConjunction">
        </a> */}
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("planetaryConjunctions")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/planetaryConjunctions"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/planetaryConjunctions"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-commit"></i> */}
          <BsBrilliance />
          <span>{t("Planetary Conjunction")}</span>
        </Link>
          </li>



      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("scope")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/dashboard/scope"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/dashboard/scope"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-commit"></i> */}
          <BsBullseye />
          <span>{t("Solar System Scope")} </span>
        </Link>
      </li>
    </>
  );
};

export default TransitionDashboard;
