import React, { Component } from "react";
import ImageMapper from "../ImageMapper";
import "./Chakra.css";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";
class ChakraTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t, i18n } = this.props;
    const { data, heading, horoscopeData } = this.props;
    console.log(data);
    return (
      <>
        <div className="main-chakra">
          {heading === "General Details" ? (
            <>
              <div>
                <div className="d-flex">
                  <Table
                    id="table-Ghatak"
                    className={"lefttable"}
                    responsive="xs"
                  >
                    <thead className="subheading"> {t(heading)}</thead>
                    <tbody>
                      {data.slice(0, 16).map(function (val, i) {
                        return (
                          <tr key={val + i}>
                            <td className="right-align">
                              {t(val["Column1"])}:
                            </td>
                            <td className="left-align">{t(val["Column2"])}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <Table className={"righttable"} responsive="xs">
                    <thead className="subheading"> {t("Panchang")}</thead>
                    <tbody>
                      {data.slice(16, 32).map(function (val, i) {
                        return (
                          <tr key={val + i}>
                            <td className="right-align">
                              {t(val["Column1"])}:
                            </td>
                            <td className="left-align">{val["Column2"]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {
                    <div className="images-div-basic">
                      <img
                        className="basic-image-asc" alt="Ascendant" src={`/assets/Zodiac/${ImageMapper[this.props.asc]}.svg`}></img>
                      <img className="basic-image-rashi"
                        alt="Zodiac" src={`/assets/Zodiac/${ImageMapper[this.props.rashi]}.svg`}></img>
                      <img
                        className="basic-image-nakshtra" alt="Nakshtra" src={`/assets/Nakshatra/${ImageMapper[this.props.nakshatra]}.svg`}></img>
                    </div>
                  }
                </div>
              </div>
            </>
          ) : heading === "Avkhada Chakra" ? (
            <>
              <div>
                <center>
                  <h2 className="chakra-title">{t(heading)}</h2>
                </center>
                <div className="d-flex">
                  <Table
                    id="table-Ghatak"
                    className={
                      heading === "Avkhada Chakra" ? "lefttableAV" : "lefttable"
                    }
                    responsive="xs"
                  >
                    <tbody>
                      {data.slice(0, 10).map(function (val, i) {
                        return (
                          <tr key={val + i}>
                            <td className="right-align">
                              {t(val["Column1"])}:
                            </td>
                            <td className="left-align">{t(val["Column2"])}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Table className={"righttableAV"} responsive="xs">
                    <thead></thead>
                    <tbody>
                      {data.slice(10, 20).map(function (val, i) {
                        return (
                          <tr key={val + i}>
                            <td className="right-align">
                              {t(val["Column1"])}:
                            </td>
                            <td className="left-align">{val["Column2"]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="images-div">
                    <img
                      className="avkhada-image"
                      alt="Avkhada"
                      src="/assets/images/Avkhada Chakra.svg"
                    ></img>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="ghatak">
                <h2 className="chakra-title">{t(heading)}</h2>
                <Table responsive="xs" className="lefttableghatak">
                  <tbody>
                    {data.map(function (val, i) {
                      return (
                        <tr key={val + i}>
                          <td className="right-align">{t(val["Column1"])}:</td>
                          {typeof val["Column2"] === "object" ? (
                            <td className="left-align">
                              {val["Column2"].join(", ")}
                            </td>
                          ) : (
                            <td className="left-align">
                              {i === 6
                                ? horoscopeData.basic_details.gender ===
                                    "Male" ||
                                  horoscopeData.basic_details.gender === "पुरुष"
                                  ? t(val["Column2"].split(",")[0])
                                  : t(val["Column2"].split(",")[1])
                                : val["Column2"]}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="images-div-ghatak">
                  <img
                    className="ghatak-image"
                    alt="Ghatak"
                    src="/assets/images/Ghatak.svg"
                  ></img>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(ChakraTable);
