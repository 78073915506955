import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import Plotly_NAKS from "./plotly_Naks"
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import moment from "moment";
import Journey from "./Journey";
import Api from "../../../API/Api";
import { Spinner } from "react-bootstrap";
// let api  = new Api()
const LongTermPlanetJourney = (props) => {

    const [view, setView] = useState("journey");
    const [showResetForm, setShowResetForm] = useState(false)
    const [formattedStartDate, setFormattedStartDate] = useState(moment(new Date(props.start || window.localStorage.getItem("longTermJourneyStartDate"))).format("YYYY/MM/DD" || null));
    const [formattedEndDate, setFormattedEndDate] = useState(moment(new Date(props.end || window.localStorage.getItem("longTermJourneyEndDate"))).format("YYYY/MM/DD" || null));
    const [language, setLanguage] = useState(window.localStorage.getItem("UserDefaultLanguage"))
    const [loading, setLoading] = useState(false);
    const [updateGraph, setUpdateGraph] = useState(false);//this will be used as toggle to update graph after api data update 

    const { t, i18n } = useTranslation();
    const context = React.useContext(GlobalContext);
    useEffect(() => {
        if (window.localStorage.getItem("longTermJourneyAPIData") === null) {
            //if api data is not in storage open the form to enter data and make api call
            resetForm();
        }
    }, [])

    useEffect(() => {
        //TODO: should update on certain props change
        setFormattedStartDate(
            moment(
                new Date(
                    window.localStorage.getItem("longTermJourneyStartDate")
                )).format("YYYY/MM/DD"));
        setFormattedEndDate(moment(new Date(window.localStorage.getItem("longTermJourneyEndDate"))).format("YYYY/MM/DD"))
        //if we only update context on alert click then we can save constant rerendering 
        setUpdateGraph(!updateGraph);
    }, [window.localStorage.getItem(
        "longTermJourneyAPIData"
    ),
    ])

    const call_api = async () => {
        setLoading(true);
        let API = new Api();
        let start_Date = new Date(window.localStorage.getItem("longTermJourneyStartDate"));
        let end_Date = new Date(window.localStorage.getItem("longTermJourneyEndDate"))
        let from_year = moment(start_Date).format("YYYY");
        let from_month = moment(start_Date).format("MM");
        let from_day = moment(start_Date).format("DD");
        let to_year = moment(end_Date).format("YYYY");
        let to_month = moment(end_Date).format("MM");
        let to_day = moment(end_Date).format("DD");
        let lat = context.requestParams.latitude;
        let long = context.requestParams.longitude;
        let offset = context.requestParams.offset;
        let planetMap = {
            Jupiter: 5,
            Saturn: 6,
            Rahu: 10,
            Ketu: 100
        };
        let planetName = context.longTermJourneyPlanet;
        let params =
            "?from_year=" +
            from_year +
            "&from_month=" +
            from_month +
            "&from_day=" +
            from_day +
            "&to_year=" +
            to_year +
            "&to_month=" +
            to_month +
            "&to_day=" +
            to_day +
            "&lat=" +
            lat +
            "&long=" +
            long +
            "&offset=" +
            offset +
            "&city=" +
            context.requestParams.name +
            "&state=" +
            context.requestParams.state +
            "&country=" +
            context.requestParams.country +
            "&reflong=" +
            context.requestParams.reference_longitude_for_timezone +
            "&timezone=" +
            context.requestParams.timezone +
            "&token=" +
            localStorage.getItem("omp_token");
        let api_result = await API.call_APIs_for_long_term_planet_journey(params, planetMap[planetName]);
        context.setLongTermJourneyAPIData(api_result);
        setLoading(false);
    }
    useEffect(() => {
        if (language !== window.localStorage.getItem("UserDefaultLanguage") && language !== null) {
                    //without if condition API gets called when calling API on first load
            call_api();

        }
        setLanguage(window.localStorage.getItem(
            "UserDefaultLanguage"
        ));
    }, [window.localStorage.getItem("UserDefaultLanguage")])

    const resetForm = () => {
        if (Object.keys(context.requestParams).length !== 0) {
            if (
                context.transitCountry === null ||
                context.transitCountry === ""
            ) {
                context.setCountry(context.requestParams.country, "Transit");
            }
            if (
                context.transitCityState === null ||
                context.transitCityState === ""
            )
                context.setCityAndState(
                    `${context.requestParams.name},${context.requestParams.state}`,
                    "Transit"
                );
        }
        // if(window.localStorage.getItem("longTermJourneyAPIData")===null){
        //if api data is not in storage open the form to enter data and make api call
        setShowResetForm(true);
        // }


    }
    return (<>
        <Sideform
            // show={this.context.showSideFormTransition}
            mode="TransitionView"
            view="longtermjourney"
            // handleClose={this.context.handleSideFormTransition}
            show={showResetForm}
            handleClose={i => setShowResetForm(false)}
            reset={true}
            history={props.history}
        />
        {/* <div className="col-lg-14">
            <div id="d3graph"> */}
        <div className="card">
            <div className="card-body" style={{ paddingLeft: "0px" }}>
                <center>
                    <div className="row" style={{ display: "inline-block" }}>
                        <center>
                            <div class="row" style={{ paddingBottom: "15px" }}>
                                <div class="col-lg-12">
                                    <form class="form-inline" style={{ fontSize: "1em" }}>
                                        <div class="form-group mx-md-3">
                                            <label class="mr-2">{t("Location")}:</label>
                                            <label class="mr-2" style={{ color: "#343a40" }}>
                                                {context.requestParams.name}
                                            </label>
                                        </div>
                                        <div class="mx-md-3">
                                            <span class="mr-2">{t("Start Date")}:</span>
                                            <label class="mr-2">{formattedStartDate}</label>
                                        </div>
                                        <div class="mx-md-3">
                                            <span class="mr-2">{t("End Date")}:</span>
                                            <label class="mr-2">{formattedEndDate}</label>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={() => resetForm()}
                                            style={{
                                                marginLeft: "40px",
                                            }}
                                        >
                                            <i className="mdi mdi-refresh"></i>
                                            {t("Reset")}
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <span class="header-title" style={{ color: "#fff" }}>
                                <div class="btn-group">
                                    <button
                                        className={`btn btn-primary ${view === "journey" ? "highlighted" : ""
                                            }`} style={{ backgroundColor: "darkcyan" }}
                                        onClick={() => setView("journey")}
                                    >
                                        {t("Journey View")}
                                    </button>
                                    <button
                                        className={`btn btn-primary ${view === "traces" ? "highlighted" : ""
                                            }`} style={{ backgroundColor: "darkcyan" }}
                                        onClick={() => setView("traces")}
                                    >
                                        {t("Traces View")}
                                    </button>
                                </div>
                            </span>
                        </center>
                    </div>
                </center>
                {loading ? (<div 
                style={{ margin: "15%",marginLeft:" 0.5 px" }}
                >
                    {/* <center> */}
                        {/* <div class="spinner-border text-primary"></div> */}
                        <div className="loader"></div>
                    {/* </center> */}
                </div>) :
                    context.longTermJourneyAPIData ? (view === "journey" ? <Journey
                        city={props.city}
                        start={formattedStartDate}
                        end={formattedEndDate}
                        selectedLanguage={localStorage.getItem(
                            "UserDefaultLanguage"
                        )}
                        tokenExpiredCount={props.tokenExpiredCount}
                        planetName={context.longTermJourneyPlanet || window.localStorage.getItem("longTermJouneyPlanet")}
                        updatetoggle={updateGraph}
                    /> :
                        <Plotly_NAKS
                            city={props.city}
                            start={formattedStartDate}
                            end={formattedEndDate}
                            selectedLanguage={localStorage.getItem(
                                "UserDefaultLanguage"
                            )}
                            tokenExpiredCount={props.tokenExpiredCount}
                            updatetoggle={updateGraph}
                        />) : ""}



            </div>
        </div>

    </>);
}

export default withRouter(LongTermPlanetJourney);